
const Button = props => {

    const {buttonText, setShowPageElement, whereTo} = props
  
    return (
        <>
            <button 
                type="button" 
                className="btn btn-outline-secondary" 
                onClick={() => setShowPageElement(whereTo)}>
                {buttonText}
            </button>
        </>
    );
  }
  
  export default Button
  

       
            
            
            
            
            
            
            
            
           