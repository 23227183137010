import Footer from "../footer/Footer";
import Header from "../header/Header";

const Datenschutz = () => {

    return (
        <>
        <div className='container'>
            <Header />
            <h4 className="display-6 mb-4" style={{textAlign: 'center', fontSize: '1.7em'}}>Datenschutzerklärung</h4>

            <h4 className="display-6 mb-4" style={{textAlign: 'center', fontSize: '1.7em'}}>ALLGEMEINES</h4><br/>

            Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) werden von uns nur 
            im Rahmen der Erforderlichkeit sowie zum Zwecke der Bereitstellung eines funktionsfähigen 
            und nutzerfreundlichen Internetauftritts, inklusive seiner Inhalte und der dort angebotenen 
            Leistungen, verarbeitet.<br/>
            Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der Datenschutz-
            Grundverordnung (nachfolgend nur „DSGVO“ genannt), gilt als „Verarbeitung“ jeder mit 
            oder ohne Hilfe automatisierter Verfahren ausgeführter Vorgang oder jede solche 
            Vorgangsreihe im Zusammenhang mit personenbezogenen Daten, wie das Erheben, das 
            Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, 
            das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, 
            Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, 
            die Einschränkung, das Löschen oder die Vernichtung.<br/>
            Mit der nachfolgenden Datenschutzerklärung informieren wir Sie insbesondere über Art, 
            Umfang, Zweck, Dauer und Rechtsgrundlage der Verarbeitung personenbezogener Daten, 
            soweit wir entweder allein oder gemeinsam mit anderen über die Zwecke und Mittel der 
            Verarbeitung entscheiden. Zudem informieren wir Sie nachfolgend über die von uns zu 
            Optimierungszwecken sowie zur Steigerung der Nutzungsqualität eingesetzten 
            Fremdkomponenten, soweit hierdurch Dritte Daten in wiederum eigener Verantwortung 
            verarbeiten.<br/>
            Unsere Datenschutzerklärung ist wie folgt gegliedert:<br/>
            I. Informationen über uns als Verantwortliche<br/>
            II. Rechte der Nutzer und Betroffenen<br/>
            III. Informationen zur Datenverarbeitung<br/><br/>

            <h4 className="display-6 mb-4" style={{textAlign: 'center', fontSize: '1.7em'}}>I. INFORMATIONEN ÜBER UNS ALS VERANTWORTLICHE</h4>
            Verantwortlicher Anbieter dieses Internetauftritts im datenschutzrechtlichen Sinne ist:<br/>
            Healing the World with human  touch, Eleonore Fischer, Höhenstr. 158, 41749  Viersen,<br/>
            Mail: service@healingtheworldwithhumantouch.org<br/>
            Web: www.healingtheworldwithhumantouch.org<br/>
            c/o Rechtsanwalt Wilfried Schmitz (rechtlicher Berater und Vertreter der Frau Eleonore 
            Fischer und des Projekts)<br/>
            De-Plevitz-Straße 2<br/>
            52538 Selfkant<br/>
            Deutschland<br/>
            Mail: ra.wschmitz@gmail.com<br/>
            Web: https://www.rechtsanwalt-wilfried-schmitz.de/kontakt/<br/><br/>

            <h4 className="display-6 mb-4" style={{textAlign: 'center', fontSize: '1.7em'}}>II. RECHTE DER NUTZER UND BETROFFENEN</h4>
            Mit Blick auf die nachfolgend noch näher beschriebene Datenverarbeitung haben die Nutzer 
            und Betroffenen das Recht<br/>
            auf Bestätigung, ob sie betreffende Daten verarbeitet werden, auf Auskunft über die 
            verarbeiteten Daten, auf weitere Informationen über die Datenverarbeitung sowie 
            auf Kopien der Daten (vgl. auch Art. 15 DSGVO);<br/>
            auf Berichtigung oder Vervollständigung unrichtiger bzw. unvollständiger Daten (vgl. 
            auch Art. 16 DSGVO);<br/>
            auf unverzügliche Löschung der sie betreffenden Daten (vgl. auch Art. 17 DSGVO), 
            oder, alternativ, soweit eine weitere Verarbeitung gemäß Art. 17 Abs. 3 DSGVO 
            erforderlich ist, auf Einschränkung der Verarbeitung nach Maßgabe von Art. 18 
            DSGVO;<br/>
            auf Erhalt der sie betreffenden und von ihnen bereitgestellten Daten und auf 
            Übermittlung dieser Daten an andere Anbieter/Verantwortliche (vgl. auch Art. 20 
            DSGVO);<br/>
            auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie der Ansicht sind, dass 
            die sie betreffenden Daten durch den Anbieter unter Verstoß gegen 
            datenschutzrechtliche Bestimmungen verarbeitet werden (vgl. auch Art. 77 DSGVO).<br/>
            Darüber hinaus ist der Anbieter dazu verpflichtet, alle Empfänger, denen gegenüber Daten 
            durch den Anbieter offengelegt worden sind, über jedwede Berichtigung oder Löschung von 
            Daten oder die Einschränkung der Verarbeitung, die aufgrund der Artikel 16, 17 Abs. 1, 18 
            DSGVO erfolgt, zu unterrichten. Diese Verpflichtung besteht jedoch nicht, soweit diese 
            Mitteilung unmöglich oder mit einem unverhältnismäßigen Aufwand verbunden ist. <br/>
            Unbeschadet dessen hat der Nutzer ein Recht auf Auskunft über diese Empfänger.<br/>
            Ebenfalls haben die Nutzer und Betroffenen nach Art. 21 DSGVO das Recht auf 
            Widerspruch gegen die künftige Verarbeitung der sie betreffenden Daten, sofern die Daten
            durch den Anbieter nach Maßgabe von Art. 6 Abs. 1 lit. f) DSGVO verarbeitet werden. <br/>
            Insbesondere ist ein Widerspruch gegen die Datenverarbeitung zum Zwecke der 
            Direktwerbung statthaft.<br/><br/>

            <h4 className="display-6 mb-4" style={{textAlign: 'center', fontSize: '1.7em'}}>III. INFORMATIONEN ZUR DATENVERARBEITUNG</h4>
            Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten werden gelöscht oder 
            gesperrt, sobald der Zweck der Speicherung entfällt, der Löschung der Daten keine 
            gesetzlichen Aufbewahrungspflichten entgegenstehen und nachfolgend keine 
            anderslautenden Angaben zu einzelnen Verarbeitungsverfahren gemacht werden.<br/><br/>

            <h4 className="display-6 mb-4" style={{textAlign: 'center', fontSize: '1.7em'}}>SERVERDATEN</h4>
            Aus technischen Gründen, insbesondere zur Gewährleistung eines sicheren und stabilen 
            Internetauftritts, werden Daten durch Ihren Internet-Browser an uns bzw. an unseren 
            Webspace-Provider übermittelt. Mit diesen sog. Server-Logfiles werden u.a. Typ und Version 
            Ihres Internetbrowsers, das Betriebssystem, die Website, von der aus Sie auf unseren 
            Internetauftritt gewechselt haben (Referrer URL), die Website(s) unseres Internetauftritts, 
            die Sie besuchen, Datum und Uhrzeit des jeweiligen Zugriffs sowie die IP-Adresse des 
            Internetanschlusses, von dem aus die Nutzung unseres Internetauftritts erfolgt, erhoben.<br/>
            Diese so erhobenen Daten werden vorrübergehend gespeichert, dies jedoch nicht 
            gemeinsam mit anderen Daten von Ihnen.<br/>
            Diese Speicherung erfolgt auf der Rechtsgrundlage von Art. 6 Abs. 1 lit. f) DSGVO. Unser 
            berechtigtes Interesse liegt in der Verbesserung, Stabilität, Funktionalität und Sicherheit 
            unseres Internetauftritts.<br/>
            Die Daten werden spätestens nach sieben Tage wieder gelöscht, soweit keine weitere 
            Aufbewahrung zu Beweiszwecken erforderlich ist. Andernfalls sind die Daten bis zur 
            endgültigen Klärung eines Vorfalls ganz oder teilweise von der Löschung ausgenommen.<br/><br/>

            <h4 className="display-6 mb-4" style={{textAlign: 'center', fontSize: '1.7em'}}>NEWSLETTER</h4>
            Falls Sie sich für unseren kostenlosen Newsletter anmelden, werden die von Ihnen hierzu 
            abgefragten Daten, also Ihre E-Mail-Adresse sowie - optional - Ihr Name und Ihre Anschrift, 
            an uns übermittelt. Gleichzeitig speichern wir die IP-Adresse des Internetanschlusses von 
            dem aus Sie auf unseren Internetauftritt zugreifen sowie Datum und Uhrzeit Ihrer 
            Anmeldung. Im Rahmen des weiteren Anmeldevorgangs werden wir Ihre Einwilligung in die 
            Übersendung des Newsletters einholen, den Inhalt konkret beschreiben und auf diese 
            Datenschutzerklärung verwiesen. Die dabei erhobenen Daten verwenden wir ausschließlich 
            für den Newsletter-Versand – sie werden deshalb insbesondere auch nicht an Dritte 
            weitergegeben.<br/>
            Rechtsgrundlage hierbei ist Art. 6 Abs. 1 lit. a) DSGVO.<br/>
            Die Einwilligung in den Newsletter-Versand können Sie gemäß Art. 7 Abs. 3 DSGVO jederzeit 
            mit Wirkung für die Zukunft widerrufen. Hierzu müssen Sie uns lediglich über Ihren Widerruf 
            in Kenntnis setzen oder den in jedem Newsletter enthaltenen Abmeldelink betätigen.<br/><br/>

            <h4 className="display-6 mb-4" style={{textAlign: 'center', fontSize: '1.7em'}}>KONTAKTANFRAGEN / KONTAKTMÖGLICHKEIT</h4>
            Sofern Sie per Kontaktformular oder E-Mail mit uns in Kontakt treten, werden die dabei von 
            Ihnen angegebenen Daten zur Bearbeitung Ihrer Anfrage genutzt. Die Angabe der Daten ist 
            zur Bearbeitung und Beantwortung Ihre Anfrage erforderlich - ohne deren Bereitstellung 
            können wir Ihre Anfrage nicht oder allenfalls eingeschränkt beantworten.<br/>
            Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO.<br/>
            Ihre Daten werden gelöscht, sofern Ihre Anfrage abschließend beantwortet worden ist und 
            der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen, wie bspw. bei 
            einer sich etwaig anschließenden Vertragsabwicklung.<br/><br/>
            
            <h4 className="display-6 mb-4" style={{textAlign: 'center', fontSize: '1.7em'}}>GOOGLE FONTS</h4>
            In unserem Internetauftritt setzen wir Google Fonts zur Darstellung externer Schriftarten ein.
            Es handelt sich hierbei um einen Dienst der Google Ireland Limited, Gordon House, Barrow 
            Street, Dublin 4, Irland, nachfolgend nur „Google“ genannt.<br/>
            Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“)
            https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active
            garantiert Google, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von 
            Daten in den USA eingehalten werden.<br/>
            Um die Darstellung bestimmter Schriften in unserem Internetauftritt zu ermöglichen, wird 
            bei Aufruf unseres Internetauftritts eine Verbindung zu dem Google-Server in den USA 
            aufgebaut.<br/>
            Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der 
            Optimierung und dem wirtschaftlichen Betrieb unseres Internetauftritts.
            Durch die bei Aufruf unseres Internetauftritts hergestellte Verbindung zu Google kann 
            Google ermitteln, von welcher Website Ihre Anfrage gesendet worden ist und an welche IP-
            Adresse die Darstellung der Schrift zu übermitteln ist.
            Google bietet unter
            https://adssettings.google.com/authenticated
            https://policies.google.com/privacy
            weitere Informationen an und zwar insbesondere zu den Möglichkeiten der Unterbindung 
            der Datennutzung.
        </div>
        <Footer />
      </>
    );
  }

export default Datenschutz