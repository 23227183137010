import { useEffect } from "react";
import { BundeslaenderDeutschland, BundeslaenderLuxemburg, BundeslaenderOesterreich, BundeslaenderOstbelgien, BundeslaenderSchweiz } from "../../enums/bundeslaender";
import { Laender } from "../../enums/laender";

const Laenderwahl = props => {

    const {land, setLand, setBundesland} = props

    const changeHandler = e => {
        setLand(e.target.value)
        if(e.target.value === Laender.Deutschland){
            setBundesland(BundeslaenderDeutschland.BadenWuerttemberg)
            return
        }
        if(e.target.value === Laender.Oesterreich){
            setBundesland(BundeslaenderOesterreich.Burgenland)
            return
        }
        if(e.target.value === Laender.Schweiz){
            setBundesland(BundeslaenderSchweiz.Zuerich)
            return
        }
        if(e.target.value === Laender.Luxemburg){
            setBundesland(BundeslaenderLuxemburg.Capellen)
            return
        }
        if(e.target.value === Laender.Ostbelgien){
            setBundesland(BundeslaenderOstbelgien.Ostbelgien)
            return
        }
    }

    useEffect(() => {
        setLand(Laender.Deutschland)
        setBundesland(BundeslaenderDeutschland.BadenWuerttemberg)
        // eslint-disable-next-line
    }, [])

    return (
        <div className="mb-3 mt-3">
        <select className="form-select form-control-lg" defaultValue={land}  id="land" name="land" onChange={changeHandler}>
            <option value={Laender.Deutschland}>Deutschland</option>
            <option value={Laender.Oesterreich}>Österreich</option>
            <option value={Laender.Schweiz}>Schweiz</option>
            <option value={Laender.Luxemburg}>Luxemburg</option>
            <option value={Laender.Ostbelgien}>Ostbelgien</option>
        </select>
 </div>
    );
}

export default Laenderwahl