import { DBTables } from '../../enums/dbTables';
import { WohnenCategories } from '../../enums/wohnenCategoties';
import Inserate from '../arbeit/Inserate';
import GrundauswahlWohnen from './GrundauswahlWohnen';

const GrundauswahlWohnenLogic = props => {

    const {plz, setPlz, wohnenCategory, setWohnenCategory, land, setLand, bundesland, setBundesland, eingangstext} = props

    return (<>
                {wohnenCategory === WohnenCategories.Startseite && <><br /><div className="card">
                    <div className="card-body" style={{textAlign: 'center', fontSize: '1.0em',backgroundColor: 'rgb(236, 236, 236)'}}>{eingangstext}</div>
                </div><br /></>}
                {wohnenCategory === WohnenCategories.Startseite && <GrundauswahlWohnen setWohnenCategory={setWohnenCategory}/>}
                {wohnenCategory === WohnenCategories.Vermieter && <Inserate plz={plz} setPlz={setPlz} land={land} setLand={setLand} bundesland={bundesland} setBundesland={setBundesland} dbTable={DBTables.WohnenVermieter} eingangstext={eingangstext}/>}
                {wohnenCategory === WohnenCategories.Mieter && <Inserate plz={plz} setPlz={setPlz} land={land} setLand={setLand} bundesland={bundesland} setBundesland={setBundesland} dbTable={DBTables.WohnenMieter} eingangstext={eingangstext}/>}
            </>)
}

export default GrundauswahlWohnenLogic