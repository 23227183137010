
const ErrorAlert = props => {

    const {message} = props
  
    return (
        <div className="alert alert-danger" style={{textAlign: 'center'}}>{message}</div>
    );
}
  
  export default ErrorAlert