
export const Success = {
    NothingYet: 0,
    Yes: 1,
    No: 2,
    WrongInput: 3, 
    WrongInputVorname: 4,
    WrongInputName: 5,
    WrongInputEmail: 6,
    WrongInputUeberschrift: 7,
    WrongInputAnzeigentext: 8,
    WrongInputAdresse: 9,
    WrongInputPlz: 10,
    WrongInputOrt: 11,
    WrongInputCaptcha: 12,
    WrongInputAgb: 13,
    WrongInputWebseite: 14,
    WrongInputNachricht: 15,
    WrongInputCategorie: 16
  };