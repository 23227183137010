import { BundeslaenderOesterreich } from "../../enums/bundeslaender";

const BundeslaenderwahlFormOesterreich = props => {

    const {form, setForm} = props

    return (
        <div className="mb-3 mt-3">
            <label htmlFor="bundesland" className="form-label">In welchem Bundesland / Kanton soll Ihre Anzeige geschaltet werden?*</label>
            <select className="form-select form-control-lg" defaultValue={BundeslaenderOesterreich.Burgenland}  id="bundesland" name="bundesland" onChange={e => setForm({...form, bundesland: e.target.value})}>
                <option value={BundeslaenderOesterreich.Burgenland}>Burgenland</option>
                <option value={BundeslaenderOesterreich.Kaernten}>Kärnten</option>
                <option value={BundeslaenderOesterreich.Niederoesterreich}>Niederösterreich</option>
                <option value={BundeslaenderOesterreich.Oberoesterreich}>Oberösterreich</option>
                <option value={BundeslaenderOesterreich.Salzburg}>Salzburg</option>
                <option value={BundeslaenderOesterreich.Steiermark}>Steiermark</option>
                <option value={BundeslaenderOesterreich.Tirol}>Tirol</option>
                <option value={BundeslaenderOesterreich.Vorarlberg}>Vorarlberg</option>
                <option value={BundeslaenderOesterreich.Wien}>Wien</option>
            </select>
        </div>
    );
}

export default BundeslaenderwahlFormOesterreich