import { BundeslaenderOstbelgien } from "../../enums/bundeslaender";

const BundeslaenderwahlFormOstbelgien = props => {

    const {form, setForm} = props

    return (
        <div className="mb-3 mt-3">
            <label htmlFor="bundesland" className="form-label">In welchem Bundesland / Kanton soll Ihre Anzeige geschaltet werden?*</label>
            <select className="form-select form-control-lg" defaultValue={BundeslaenderOstbelgien.Ostbelgien} id="bundesland" name="bundesland" onChange={e => setForm({...form, bundesland: e.target.value})}>
                <option value={BundeslaenderOstbelgien.Ostbelgien}>Deutschsprachige Gemeinschaft in Ostbelgien</option>
            </select>
        </div>
    );
}

export default BundeslaenderwahlFormOstbelgien