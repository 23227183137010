import { useState } from "react";
import { Success } from "../../enums/success";
import { inputRegex, validateCaptcha, validateInput } from "../../validationLogic";
import axios from 'axios'
import ErrorAlert from "../alerts/ErrorAlert";
import MailBackForm from "./MailBackForm";
import { useTranslation } from "react-i18next";
import { getApiString } from "../../validationLogic";

const MailBackFormLogic = props => {

    const {dbTable, id, anzeigenUeberschrift, setShowMailBack, success, setSuccess} = props

    const emptyForm = {
        id: id,
        ueberschrift: anzeigenUeberschrift,
        vorname: '',
        name: '',
        email: '',
        nachricht: '',
    }

    const [form, setForm] = useState(emptyForm)
    const [loading, setLoading] = useState(false)
    const [captchaAsk, setCaptchaAsk] = useState({
        num1: Math.floor(Math.random() * 10), 
        num2: Math.floor(Math.random() * 10)
    })
    const [captchaResult, setCaptchaResult] = useState('')
    const [checkAgb, setCheckAgb] = useState(false)
    const {t, i18n} = useTranslation('common')

    const clickHandler = () => {

        setSuccess(Success.NothingYet)

        if(!validateInput(form.vorname, inputRegex.pflichtfeld_max50)){
            setSuccess(Success.WrongInputVorname)
            return
        }
        if(!validateInput(form.name, inputRegex.pflichtfeld_max100)){
            setSuccess(Success.WrongInputName)
            return
        }
        if(!validateInput(form.email, inputRegex.email)){
            setSuccess(Success.WrongInputEmail)
            return
        }
        if(!validateInput(form.nachricht, inputRegex.pflichtfeld_max1000)){
            setSuccess(Success.WrongInputNachricht)
            return
        }
        if(!validateCaptcha(captchaResult, captchaAsk.num1, captchaAsk.num2)){
            setCaptchaAsk({
                        num1: Math.floor(Math.random() * 10), 
                        num2: Math.floor(Math.random() * 10)
                    })
            setSuccess(Success.WrongInputCaptcha)
            return
        }
        if(!checkAgb){
            setSuccess(Success.WrongInputAgb)
            return
        }

        setLoading(true)

        axios.post(getApiString(i18n.language) + 'inserate/mailback/' + dbTable, form, {headers: {'Accept': 'application/json'}})
        .then(() => {
            setSuccess(Success.Yes)
            setForm(emptyForm)
            setShowMailBack(false)
            setLoading(false)
        })
        .catch(() => {
            setSuccess(Success.No)
            setForm(emptyForm)
            setShowMailBack(false)
            setLoading(false)
        })
    }
  
    return (
        <>
            <MailBackForm clickHandler={clickHandler} form={form} setForm={setForm} loading={loading} captchaAsk={captchaAsk} setCaptchaResult={setCaptchaResult} checkAgb={checkAgb} setCheckAgb={setCheckAgb}/>
            {success === Success.WrongInputVorname && <ErrorAlert message={t('alerts.InputErrorVorname')}/>}
            {success === Success.WrongInputName && <ErrorAlert message={t('alerts.InputErrorName')}/>}
            {success === Success.WrongInputEmail && <ErrorAlert message={t('alerts.InputErrorEmail')}/>}
            {success === Success.WrongInputNachricht && <ErrorAlert message={t('alerts.InputErrorNachricht')}/>}
            {success === Success.WrongInputCaptcha && <ErrorAlert message={t('alerts.InputErrorCaptcha')}/>}
            {success === Success.WrongInputAgb && <ErrorAlert message={t('alerts.InputErrorAgb')} />}
        </>
    );
  }
  
  export default MailBackFormLogic