import { useTranslation } from "react-i18next";
import { ArbeitCategories } from "../../../enums/arbeitCategories";
import ButtonBig from "../../general/ButtonBig";

const Grundauswahl = props => {

    const {setArbeitCategory} = props
    const {t} = useTranslation('common')

    return (<>
                <ButtonBig clickHandler={() => setArbeitCategory(ArbeitCategories.Angebote)} buttonText={t('home.AngeboteVonArbeitgebern')}/>
                <br />
                <ButtonBig clickHandler={() => setArbeitCategory(ArbeitCategories.Gesuche)} buttonText={t('home.GesucheVonArbeitnehmern')}/>
            </>);
}

export default Grundauswahl