import { useEffect } from "react";
import { BundeslaenderUSA } from "../../enums/bundeslaender";
import { Laender } from "../../enums/laender";

const LaenderwahlFormUSA = props => {

    const {form, setForm} = props

    const changeHandler = e => {
        // does nothing
    }

    useEffect(() => {
        setForm({...form, land: Laender.USA, bundesland: BundeslaenderUSA.Alaska})
        // eslint-disable-next-line
    }, [])

    return (
        <div className="mb-3 mt-3">
                <label htmlFor="land" className="form-label">Land*</label>
                <select className="form-select form-control-lg" defaultValue={Laender.USA}  id="land" name="land" onChange={e => changeHandler(e)}>
                    <option value={Laender.USA}>USA</option>
                </select>
        </div>
    );
}

export default LaenderwahlFormUSA
