
import { Laender } from "../../enums/laender";
import MenuUSA from "../usa/MenuUSA";
import MenuDeutschland from "./MenuDeutschland";
import MenuLuxemburg from "./MenuLuxemburg";
import MenuOesterreich from "./MenuOesterreich";
import MenuOstbelgien from "./MenuOstbelgien";
import MenuSchweiz from "./MenuSchweiz";

const Bundeslaenderwahl = props => {

    const {land, bundesland, setBundesland} = props

    return (
        <>
            {land === Laender.Deutschland && <MenuDeutschland bundesland={bundesland} setBundesland={setBundesland}/>}
            {land === Laender.Oesterreich && <MenuOesterreich bundesland={bundesland} setBundesland={setBundesland}/>}
            {land === Laender.Schweiz && <MenuSchweiz bundesland={bundesland} setBundesland={setBundesland}/>}
            {land === Laender.Luxemburg && <MenuLuxemburg bundesland={bundesland} setBundesland={setBundesland}/>}
            {land === Laender.Ostbelgien && <MenuOstbelgien bundesland={bundesland} setBundesland={setBundesland}/>}
            {land === Laender.USA && <MenuUSA bundesland={bundesland} setBundesland={setBundesland}/>}
        </>
    );
}

export default Bundeslaenderwahl
