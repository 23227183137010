
const LoadingSpinner = () => {
  
    return (
      <div style={{textAlign: 'center'}}>
        <div className="spinner-border"></div>
      </div>
    );
}

export default LoadingSpinner
