
export const Categories = {
    Startseite: 'Startseite',
    Arbeit: 'Arbeit',
    Partner: 'Partner',
    Gastronomie: 'Gastronomie',
    Kinder: 'Kinder',
    Kultur: 'Kultur',
    Helfen: 'Helfen',
    Heilen: 'Heilen',
    Shopping: 'Shopping',
    Wohnen: 'Wohnen',
    Freizeit: 'Freizeit',
    Freunde: 'Freunde',
    StartUps: 'Startups',
    Mitfahrgelegenheit: 'Mitfahrgelegenheit',
    KritischeAnwaelte: 'Kritische Anwälte',
    Handwerk: 'Handwerk',
    AutarkesLeben: 'Autarkes Leben'
  };