import Footer from "../footer/Footer";
import Header from "../header/Header";

const Agb = () => {

    const underline = {
      textDecoration: 'underline'
    }

    const bold = {
      fontWeight: 'bold'
    }

    return (
      <>
        <div className='container'>
            <Header />
            <h4 className="display-6 mb-4" style={{textAlign: 'center', fontSize: '1.7em'}}>Unsere Allgemeinen Nutzungsbedingungen<br/>

            <br/>Allgemeine Bedingungen für die Nutzung von „Healing the World with human touch“ im deutschsprachigen Raum (Deutschland / Österreich / deutschsprachige Schweiz)

            <br/><br/>Stand: Dezember 2021
            <br/><br/>
I.<br/><br/>

Einleitung</h4>

Auch insofern wäre es wohl an der Zeit für eine „Zeitenwende“, indem wir den Menschen, die uns kontaktieren, wieder das Vertrauen in ihre Fähigkeit zu verantwortungsbewusstem und rücksichtsvollem Handeln entgegenbringen, dass die Politik ihnen in den letzten Jahren in zunehmendem Maße abgesprochen hat. <br/><br/>

Braucht der verantwortungsbewusst und redlich agierende 1000 x 1000 Mensch denn wirklich Gesetze und Richtlinien, damit er weiß wie er sich richtig zu verhalten hat?<br/><br/>

Nach unserem Eindruck ist die Welt ist die Freiheit des Menschen schon dann bedroht, wenn er stets in irgendwelchen aufgeschriebenen Texten nachlesen muss damit er nun weiß, was er angeblich noch alles darf und was er nicht mehr darf.<br/><br/>

In dem <span style={bold}>„Friedensevangelium der Essener</span> (siehe u.a.:  Schriften der Essener, Buch 1, ISBN-10: 3890601278, ISBN-13: 978-3890601274) heißt es u.a. (Zitat) <br/><br/>
„[…] Sie saßen rund um Jesus und fragten ihn: »Meister, welches sind die Gesetze des Lebens? Weile länger bei uns und lehre uns. Wir möchten deinen Worten lauschen, damit wir geheilt und rechtschaffen werden.«<br/><br/>
Und Jesus antwortete: »Sucht das Gesetz nicht in euren heiligen Schriften; denn das Leben ist das Gesetz, die Schrift jedoch ist tot. … Denn wahrlich, ich sage euch, alles, was lebt, ist näher bei Gott als die Schrift, die ohne Leben ist. Gott schuf das Leben und alles, was da lebt, damit sie durch das ewig lebendige Wort dem Menschen die Gesetze der wahrhaften Gottheit lehren. <span style={bold}>Gott schrieb die Gesetze nicht in die Seiten der Bücher, sondern in euer Herz und in euren Geist.</span> Sie sind in eurem Atem, eurem Blut, euren Knochen, in eurem Fleisch, euren Eingeweiden, euren Augen, euren Ohren, und in jedem winzigen Teilchen eures Leibes. Sie sind allgegenwärtig in der Luft, im Wasser, in der Erde, in den Pflanzen, in den Sonnenstrahlen, in den Tiefen und in den Höhen. Sie alle reden zu euch, damit ihr das Wort und den Willen der lebendigen Gottheit verstehet…“<br/><br/>

Menschen, die verantwortungsbewusst und rücksichtsvoll agieren, müssen – so glauben wir – also nicht im Detail darüber belehrt werden, was sie zu tun oder zu lassen haben. Sie wissen in ihrem Herzen was „Recht“ und „Unrecht“ ist.<br/><br/>

Wir wollen Menschen eine Plattform bieten, die respektvoll, fair, sachlich und niveauvoll miteinander umgehen können. <br/><br/>

Für jeden Nutzer unserer Plattform muss es selbstverständlich sein, dass er uns und Dritten gegenüber nur wahrheitsgemäße Angaben macht und seine Sprache sachlich, freundlich und respektvoll ist und bleibt.<br/><br/>

Wer diese Selbstverständlichkeiten nicht beachtet, weil er uns oder den Nutzern unserer Webseite per Mail oder auf anderem Wege Texte oder sonstige Botschaften übermittelt, die unsachlich, anzüglich, belästigend, rassistisch oder aus sonstigen Gründen zivilrechtliche Unterlassungsansprüche auslösen können oder sogar von strafrechtlicher Relevanz sind, dem werden wir keine Plattform bieten. <br/><br/>

Solche Inserate werden wir auf unserer Homepage nicht veröffentlichen.<br/><br/>

Wer Nutzern unserer Plattform gegenüber wissentlich und willentlich Angaben macht, die unwahr sind und deshalb bei dem Dritten eine Fehlvorstellung auslösen müssen, kann sich u.U. wegen Betruges oder auch wegen anderer Delikte strafbar machen, von dem emotionalen Betrug und Schaden, der damit verwirklicht wird, gar nicht zu reden. <br/><br/>
Auf Grund eigener Erkenntnisse und zahlreicher Anregungen haben wir uns schließlich mit dieser Plattform „Healing the World with human touch“ in jeder Hinsicht neu aufgestellt: übersichtlicher, breiter aufgestellt, und sicherheitstechnisch auf dem maximal möglichen Niveau.<br/><br/>
<span style={bold}>Wir stellen ausdrücklich klar, dass wir keinen Einblick in die Korrespondenzen und Daten haben, die Inserenten und Interessenten miteinander austauschen. Alle Mails gehen nicht über unsere Plattform, sondern jeweils direkt von Account zu Account. Diese Korrespondenzen gehen uns auch nichts an.</span> <br/><br/>

Wir übernehmen keinerlei Haftung für die Richtigkeit der Angaben, die Inserenten auf unserer Plattform einstellen lassen. Jeder Nutzer unserer Plattform ist aufgefordert, die Richtigkeit dieser Angaben im Kontakt mit dem Inserenten zu hinterfragen, wenn ihn Zweifel an der Richtigkeit kommen. Das Gleiche gilt umgekehrt natürlich auch für Inserenten, die von Interessenten kontaktiert werden.<br/><br/>

Insbesondere sollte niemand vorschnell seine persönlichen Kontaktdaten wie Adresse und Handy-Nr. an Personen herausgeben, zu denen er über unsere Plattform Kontakt aufgenommen hat. Der beste Schutz ist höchste Achtsamkeit und Vorsorge, und ein gesundes Misstrauen und zumutbare Kontrolle – etwa auch über eine Zoom-Konferenz im Rahmen einer ersten direkten Kontaktaufnahme – ist immer besser als blindes Vertrauen in die Integrität von Personen, die vielleicht nur schöne Worte machen. <br/><br/>

Ohne ein gewisses Grundvertrauen können wir nicht miteinander kommunizieren und Netzwerke aufbauen. Also zerstören Sie dieses Vertrauen bitte nicht bei den Menschen, die Ihnen dieses Vertrauen entgegenbringen. <br/><br/>

Unsere Leistungen werden als Dienst an der Gesellschaft und zum Zwecke der Vernetzung von Menschen auf freiwilliger und ehrenamtlicher Basis erbracht. Deshalb besteht kann niemand einen einklagbaren Anspruch auf unsere Leistungen oder die Beibehaltung unserer einzelnen Rubriken geltend machen, auch wenn wir stets bemüht sein wollen allen Inseratswünschen gerecht zu werden. <br/><br/>

Wir können im Rahmen unseres Projekts auch keine Rechtsberatung oder Lebenshilfe anbieten. Unsere Plattform ist die Hilfe, die wir der Gesellschaft anbieten wollen und können.<br/><br/>

Wir werden alle eingehenden Daten vertraulich behandeln und im Einklang mit den geltenden Datenschutzbestimmungen erheben, verarbeiten und veröffentlichen. Hierzu verweisen wir im Übrigen auf unsere separate Datenschutzerklärung.<br/><br/>

Da die Welt aktuell noch nicht ohne detaillierte Regelungen auskommen dürfte, da in diesen Zeiten eben nicht nur freundlich gesinnte Menschen unterwegs sind, haben wir unsere Allgemeinen Nutzungsbedingungen nachfolgend im Detail geregelt.<br/><br/>

<h4 className="display-6 mb-4" style={{textAlign: 'center', fontSize: '1.7em'}}>II.<br/><br/>

Unsere Allgemeinen Nutzungsbedingungen im Detail</h4><br/><br/>

Diese Allgemeinen Bedingungen gelten für die Beziehungen zwischen „Healing the World with human touch“ und seinen Nutzern, und zwar unabhängig davon, ob Sie „Healing the World with human touch“ über die Webseite oder mobile Apps erreichen.<br/><br/>
1.<br/><br/>
<span style={underline}>Leistungen von „Healing the World with human touch“ </span><br/><br/>
Die von „Healing the World with human touch“ angebotene Leistung ermöglicht den Inserenten die entgeltfreie Veröffentlichung ihrer Inseratswünsche auf unserer Plattform in den von uns zur Verfügung gestellten Rubriken:<br/><br/>
Arbeit <br/>
Partner <br/>
Gastronomie <br/>
Kinder<br/>
Shopping<br/>
Wohnen <br/>
Kultur<br/>
Freizeit<br/>
Helfen<br/>
Freunde<br/>
Heilen (gegliedert in: Schulmedizin / Naturheilkunde / Spiritualität)<br/>
Startups<br/>
Mitfahrgelegenheiten<br/>
Kritische Anwälte<br/>
Handwerk<br/><br/>
Für die Erfassung der Daten aller Nutzer stellt „Healing the World with human touch“ unter den oben genannten Rubriken Kontaktformulare (Datenerfassungsmasken) zur Verfügung, die von den Nutzern mit Daten befüllt werden. <br/><br/>
Aus den Eingabefeldern dieser Kontaktformulare ergibt sich, welche Daten im jeweiligen Zusammenhang erhoben werden.<br/><br/>
Von diesen Daten werden wir lediglich den Anzeigentext und den Email-Account veröffentlichen, damit sich die Nutzer unserer Plattform über den Inhalt ihres Inserats informieren können und Kontakt mit Ihnen aufnehmen können.  <br/><br/>
Die Namen und die Anschrift der Inserenten werden wir nicht veröffentlichen, es sei denn, dass dies – wie z.B. in der Rubrik „Gastronomie“ gerade dem Zweck der Inserate dient. Aber wir benötigen diese Daten, damit wir erkennen können, mit wem wir korrespondieren und wer für die uns übermittelten Daten verantwortlich ist. Hierbei verfahren wir aber nach dem Grundsatz „Nur das, was wir mindestens benötigen, um miteinander kommunizieren zu können.“<br/><br/>
Wir werden alle Daten, die die Inserenten übermittelt haben, löschen, wenn diese uns um die Löschung ihres Inserats ersuchen werden, etwa weil sich der Zweck ihres Inserats erfüllt hat oder weil kein Interesse mehr an dem Fortbestand des Inserats besteht. Darüber hinaus werden alle Inserate und Daten in den nachfolgend genannten Intervallen automatisch gelöscht. <br/><br/>
Der bei der Erfassung der Inserate angegebene Vorname und Name sowie der Mail-Account des Inserenten werden an den Interessenten übermittelt, damit der Interessent sehen kann mit wem er korrespondiert.<br/><br/>
Inserenten, die ein besonderes Interesse an der Geheimhaltung ihres Namens haben, steht es frei, bei der Angabe Ihres Namens ein Pseudonym (und einen Mail-Account ohne persönliche Daten) zu verwenden. Sie müssen dann aber bereits in Ihrem Inserat offenlegen, dass es sich um ein Pseudonym handelt.<br/><br/>
2.<br/><br/>
<span style={underline}>Wo ist „Healing the World with human touch“ aktiv?</span><br/><br/>
Wir bieten unsere Leistungen zunächst in allen deutschsprachigen Ländern (Deutschland / Österreich / deutschsprachige Schweiz) an, so dass wir in jedem dieser Länder eine regionale und überregionale Suche und Kontaktaufnahme ermöglichen.<br/><br/>
Gerne veröffentlichen wir aber auch – soweit möglich - Inserate, die sich auf Kontaktwünsche außerhalb des deutschsprachigen Raums beziehen.<br/><br/>
Das jeweilige Inseratsprofil der Inserenten ist in allen deutschsprachigen Ländern sichtbar.<br/><br/>
Wir behalten uns vor, zu gegebener Zeit auch für den englischsprachigen Raum ein vergleichbares Angebot in englischer Sprache zur Verfügung zu stellen. Angebote in allen anderen Weltsprachen (spanisch, portugiesisch, französisch etc.) sind vielleicht irgendwann ebenfalls möglich. <br/><br/>
3.<br/><br/>
<span style={underline}>Was will „Healing the World with human touch“?</span><br/><br/>
„Healing the World with human touch“ ist eine Plattform zum Aufbau sozialer Netzwerke und will deshalb allen Nutzern die Möglichkeit bieten, sich gegenüber Dritten gemäß den eigenen Wünschen und Vorstellungen zu präsentieren und mit Dritten Kontakte zu knüpfen.<br/><br/>
4.<br/><br/>
<span style={underline}>Datenübermittlung / Bestätigungsmail / Kooperationsverhältnis</span><br/><br/>

Die Einstellung eines Inserats setzt die Übermittlung der Daten der Inserenten voraus.<br/><br/> 
Voraussetzung dafür ist die vollständige Übermittlung aller Pflichtinhalte, die von unseren Kontaktformularen erfragt werden. <br/><br/>
Mit dem Erhalt der Daten entsteht zunächst ein kostenloses und rein ehrenamtliches Kooperationsverhältnis zwischen „Healing the World with human touch“ und dem uns kontaktierenden Nutzer, das sich nach den Regelungen dieser Allgemeinen Bedingungen richtet.<br/><br/>
5.<br/><br/>
<span style={underline}>Finanzierung von „Healing the World with human touch“</span><br/><br/>
„Healing the World with human touch“ finanziert sich durch die Schenkungen derer, die die Vernetzungsarbeit von „Healing the World with human touch“ unterstützen möchten.  <br/><br/>
„Healing the World with human touch“ stellt seine Leistungen sowohl für Inserenten als auch für die Interessenten der auf seiner Plattform veröffentlichten Inserate ohne Entgelt zur Verfügung.<br/><br/>
„Healing the World with human touch“ behält sich aber vor, seine Leistungen oder einzelne Komponenten seiner Leistungen irgendwann nach vorheriger Ankündigung nur noch gegen ein Entgelt zur Verfügung zu stellen, entweder, weil mit der weiteren Pflege der Inseratswünsche ein so großer (Kosten-)Aufwand entstehen würde, dass „Healing the World with human touch“ neue Verwaltungsstrukturen aufbauen müsste um diesen Aufwand noch auffangen zu können, oder weil uns dies aus anderen Gründen angemessen erscheint. <br/><br/>
Eine solche Kostenpflicht ist aber lediglich für die Vermittlung von Immobilien an Käufer vorgesehen.<br/><br/>
6.<br/><br/>
<span style={underline}>Das Konto von „Healing the World with human touch“</span><br/><br/>
Das Bankkonto von Connecting Life wird auf der Homepage unter dem Link „Spenden“ angegeben. <br/><br/>
<span style={bold}>Bitte geben Sie auf Ihrer Überweisung an uns als Verwendungszweck „Schenkung“ an.</span> <br/><br/>
Wir bedanken uns vorab bei allen Unterstützern unserer Plattform. Ohne Ihre Zuwendung ist unsere Arbeit nicht möglich.  <br/><br/>
7.<br/><br/>
<span style={underline}>Die Arbeit des Teams von „Healing the World with human touch“</span> <br/><br/>
Unser Team ist bestrebt unsere Leistungen an sieben Tagen der Woche zur Verfügung zu stellen.<br/><br/>
Die Erbringung unserer Leistungen hängt aber stets davon ab, wann die Mitglieder unseres Teams die Zeit finden, sich neben ihren sonstigen täglichen Verpflichtungen der ehrenamtlichen Pflege der Daten von „Healing the World with human touch“ zu widmen.<br/><br/>
Wir haben unser Portal aber soweit optimiert, dass wir nunmehr auch mit sehr geringem Arbeitseinsatz eine sehr große Menge an täglichen Inseraten sehr schnell auf ihren seriösen Inhalt hin prüfen und dann auch veröffentlichen können.<br/><br/>
Wir bitten um Geduld, wenn wir – wie in Phasen besonders starker Resonanz – ein wenig Zeit benötigen, um die Inserate hochladen zu können. <br/><br/>
8.<br/><br/>
<span style={underline}>Haftungsfragen</span><br/><br/>
„Healing the World with human touch“ übernimmt keine Gewährleistung oder Haftung für den ununterbrochenen Betrieb bzw. die jederzeitige, ordnungsgemäße Nutzbarkeit bzw. Erreichbarkeit seiner Leistungen.  <br/><br/>
„Healing the World with human touch“ übernimmt insbesondere keine Haftung, falls es zu Störungen oder Leistungsausfällen aufgrund von höherer Gewalt oder sonstigen Ereignissen kommt, die außerhalb des Einflussbereiches von „Healing the World with human touch“ liegen. <br/><br/>
Schließlich haftet „Healing the World with human touch“ nicht für den Fall, dass sich Dritte sich unbefugt bzw. in strafbarer Weise, z.B. durch einen „Hackerangriff“ Kenntnis von den persönlichen Daten eines Nutzers verschaffen. <br/><br/>
In solchen Hacker-Fällen wird „Healing the World with human touch“ allen Betroffenen dazu raten, im eigenen Namen umgehend bei den zuständigen Strafverfolgungsbehörden Strafanzeige zu erstatten und Strafantrag zu stellen, und auch im eigenen Namen solche Strafanzeigen erstatten. <br/><br/>
Wir haben uns aber technisch so aufgestellt, dass solche Hackerangriffe von vornherein aussichtslos sein dürften. <br/><br/>
9.<br/><br/>
<span style={underline}>Schutz vor Missbrauch unserer Leistungen</span><br/><br/>
„Healing the World with human touch“ ist stets bemüht, seine Nutzer vor Belästigungen wie beispielsweise wie Spam oder Betrügereien durch vorgetäuschtes Interesse und vorgetäuschte Hilfsbereitschaft zu schützen. <br/><br/>
Es ist aber eine Obliegenheit aller Nutzer, stets höchste Achtsamkeit walten zu lassen und insbesondere nicht vorschnell persönliche Kontaktdaten wie die eigene Anschrift oder Telefon-/Handy-Nr. an unbekannte Dritte herauszugeben. „Trau Schau Wem“ sollte stets von jedem Nutzer im eigenen Interesse beherzigt werden. <br/><br/>
Wenn Nutzer konkrete Anhaltspunkte für eine missbräuchliche Nutzung unserer Plattform erhalten, auch durch die unbefugte Verwendung seines Accounts, oder von einem Nutzer unseres Portals in irgendeiner Form belästigt oder beleidigt oder aus sonstigen Gründen nicht korrekt behandelt werden, so mögen sie sich unverzüglich per Mail mit uns in Verbindung setzen und uns alle relevanten Sachverhalte mitteilen. <br/><br/>
10.<br/><br/>
<span style={underline}>Beendigung der Kooperation</span><br/><br/>
Jeder Nutzer kann jederzeit per Mail um die Löschung seines Inserats bitten. <br/><br/>
Eine Kündigung ist nicht erforderlich, da kein Nutzer durch vertragliche Verpflichtungen an „Healing the World with human touch“ gebunden ist. <br/><br/>
Im Übrigen kann es unter den nachfolgend unter Ziff. 12 genannten Fällen (Verletzung der dort genannten Pflichten) zu einer Beendigung der Kooperation kommen. <br/><br/>
11.<br/><br/>
<span style={underline}>Weitere Haftungsausschlüsse</span><br/><br/>

„Healing the World with human touch“ übernimmt keine Verantwortung für unrichtige Angaben der Nutzer (Inserenten, Leser) seines Portals oder dafür, dass sich Inserenten bei der Übermittlung ihrer Inseratswünsche der für sie passenden Rubrik zugeordnet haben. <br/><br/>
Die Richtigkeit der Angaben der Nutzer unseres Portals können wir daher gerade nicht garantieren.<br/><br/>
Auch können wir keinem Nutzer einen Vermittlungserfolg zusichern. <br/><br/>
„Healing the World with human touch“ übernimmt auch keine Haftung für den Fall, dass Nutzer in unzulässiger oder gesetzeswidriger Weise seine Leistungen missbrauchen.<br/><br/>
Auch für den Missbrauch von Informationen kann „Healing the World with human touch“ nicht haftbar gemacht werden, insbesondere dann nicht, wenn ein Nutzer selbst seine Daten einem Dritten zugänglich gemacht hat.<br/><br/>
12.<br/><br/>
<span style={underline}>Pflichten der Nutzer von „Healing the World with human touch“</span> <br/><br/>
Innerhalb der Übermittlung der Inseratswünsche und der Kontaktaufnahme mit unseren Inserenten obliegt es allen Inserenten, wahrheitsgemäße Angaben zu machen. <br/><br/>
Für den Inhalt und die Richtigkeit seiner persönlichen Daten ist allein der Nutzer (Inserent/Nutzer) verantwortlich und er sichert die Richtigkeit der Angaben zu. <br/><br/>
„Healing the worldd with human touch“ hat keinen Zugang und Einblick in die Korrespondenzen und Daten, die zwischen Inserenten und Interessenten im Rahmen ihrer Kontaktaufnahme per Mail ausgetauscht werden. Diese Daten werden direkt und ausschließlich von Account zu Account der beteiligten Inserenten und Interessenten übermittelt. <br/><br/>
„Healing the World with human touch“ behält sich vor, gegen Nutzer seines Portals, die in vorsätzlicher und/oder betrügerischer Absicht falsche Angaben machen oder eine falsche Identität vorspiegeln, straf- und zivilrechtliche Schritte einzuleiten. <br/><br/>
Die Leistungen von „Healing the World with human touch“ sind ausschließlich für die private Nutzung vorgesehen, abgesehen von Rubriken wie insbesondere „Heilen“ und „Essen“, wo Selbständige und Gewerbetreibende ihre Leistungen anbieten möchten. <br/><br/>
Inseratswünsche zu allen Varianten des „Empfehlungs-Marketings“ <span style={underline}>möchten wir grundsätzlich nicht mehr veröffentlichen</span>, es sei denn, dass es sich wirklich um konkrete Job-Angebote mit eindeutig erkennbarer Verantwortlichkeit (Weblink mit Impressum), eindeutiger Stellenbeschreibung / eindeutigem Tätigkeitsprofil und konkreten Vergütungsangeboten handelt. <br/><br/>
Mit der Nutzung der Leistungen von „Healing the World with human touch“ gehen die Nutzer die Verpflichtung ein, die ihnen zugänglich gemachten Daten von Nutzern – über den jeweils konkreten Kontakt hinaus, den wir vermitteln konnten - nicht zu kommerziellen oder Werbezwecken zu verwenden.<br/><br/> 
Insbesondere ist es ausdrücklich nicht gestattet, die Daten von Nutzern unseres Portals – ganz gleich, auf welchem Wege - systematisch zu sammeln und für andere Zwecke als den von „Healing the World with human touch“ verfolgten Zweck, mit Inserenten in Kontakt zu treten, zu nutzen. <br/><br/>
In diesem Zusammenhang ist es insbesondere auch nicht gestattet, Computerprogramme zum Auslesen der Daten von Nutzern zu verwenden. <br/><br/>
Alle Nutzer der Leistungen von „Healing the World with human touch“ verpflichten sich insbesondere dazu:<br/><br/>
Keine sittenwidrigen, obszönen, pornografischen oder rechts-/linksradikalen Inhalte oder Fotos in Inseraten oder Mails an „Healing the World with human touch“ oder an andere Nutzer zu übermitteln oder zu verbreiten;<br/><br/>
über das Portal von „Healing the World with human touch“ oder die über „Healing the World with human touch“ geknüpften Kontakte keine diffamierenden, anstößigen oder in sonstiger Weise rechtswidrigen Informationen zu verbreiten;<br/><br/>
andere Personen nicht zu bedrohen, zu belästigen oder die Rechte (einschließlich Persönlichkeitsrechte) Dritter zu verletzen;<br/><br/>
keine Daten hochzuladen, die einen Virus enthalten (infizierte Software) oder Software oder anderes Material enthalten, das urheberrechtlich geschützt ist, es sei denn, dass der Kunde die Rechte daran hat oder über die erforderlichen Zustimmungen die Rechteinhabers verfügt;<br/><br/>
keine E-Mails/Nachrichten abzufangen und auch nicht zu versuchen, sie abzufangen;<br/><br/>
E-Mails/Nachrichten (auch Videochat-Nachrichten) an Nutzer zu keinem anderen Zweck als der Kommunikation zu versenden und insbesondere das Anpreisen oder Anbieten von Waren oder Dienstleistungen an andere Kunden zu unterlassen (ausgenommen in Fällen, in denen dies ausdrücklich dem Zweck der jeweiligen Kontaktvermittlung entspricht);<br/><br/>
Keine Spam-Mails oder Kettenbriefe zu versenden;<br/><br/>
Alle Nutzer des Portals von „Healing the World with human touch“ sind verpflichtet, alle personenbezogenen Daten von Nutzern von „Healing the World with human touch“ vertraulich zu behandeln und diese nicht ohne Einverständnis ihres Urhebers Dritten zugänglich zu machen. Dies gilt insbesondere für Namen, Telefon- und Faxnummern, Wohn-, Email-Adressen und sonstige vertrauliche Daten und Mitteilungen unserer Nutzer.<br/><br/>
Die Nichtbeachtung einer der vorgenannten Verpflichtungen kann zu folgenden Sanktionen führen: <br/><br/>
Aufforderung zur Stellungnahme im Falle des Verdachts von Vertrags-/Gesetzesverstößen, <br/><br/>
Sperrung der Nutzung bis zur Aufklärung von Sachverhalten (insbesondere bei mehreren Beschwerden anderer Kunden), <br/><br/>
Verwarnung, <br/><br/>
Ein-/Beschränkung bei der Nutzung der Dienstleistungen von „Healing the World with human touch“, etwa durch teilweises oder vollständiges Löschen von Inseraten.<br/><br/>
Die Nichtbeachtung der genannten Pflichten kann bei erheblichen Verstößen darüber hinaus auch zu einer sofortigen und ggf. auch dauerhaften Beendigung der Kooperation führen und auch zivil- und strafrechtliche Folgen haben. <br/><br/>
Sofern ein Nutzer im Rahmen der Nutzung der Leistungen von „Healing the World with human touch“  seine Sorgfaltspflichten schuldhaft verletzt und dadurch zivilrechtliche Forderungen von „Healing the World with human touch“, Nutzern oder sonstigen Dritten ausgelöst hat, ist er dazu verpflichtet, „Healing the World with human touch“ gegenüber von jeglicher Art von Schäden, Schadenersatzansprüchen, sonstigen Forderungen, Klagen, Anwalts- und Gerichtskosten freizustellen, die durch diese schulhafte Sorgfaltspflichtverletzung entstehen könnten. Dies gilt insbesondere für Schäden wegen der Begehung von Straftaten wie übler Nachrede, Beleidigung, Verletzung von Persönlichkeitsrechten, wegen des Ausfalls von Dienstleistungen für andere Kunden, einem Verstoß gegen diese Allgemeinen Bedingungen, wegen der Verletzung von Immaterialgütern oder sonstiger Rechte. <br/><br/>
13.<br/><br/>
<span style={underline}>Intervallmäßige automatische Löschungen und Löschungsrechte</span><br/><br/>
Damit es auf unserem Portal im Laufe der Zeit nicht immer mehr Inserate ansammeln, um deren Löschung uns der Inserent nicht ersucht hat, obschon er – aus welchen Gründen auch immer – kein Interesse mehr an dem Fortbestand des Inserats hat, <span style={bold}>werden wir alle Inserate in Intervallen von 5 Monaten automatisch löschen.</span><br/><br/>
In diesem Falle müssen die davon betroffenen Nutzer unseres Portals ihre Inseratswünsche erneut über unsere Kontaktformulare übermitteln. <br/><br/>
„Healing the World with human touch“ ist somit berechtigt, Inserate und alle damit im Zusammenhang stehenden Daten jeweils nach Ablauf von 6 Monaten nach ihrer Veröffentlichung ohne Rückfrage zu löschen. <br/><br/>
Aus diesem Grund sind alle Nutzer aufgefordert, die für sie eingehenden Mitteilungen sowie die Daten ihres Inserats in regelmäßigen und angemessenen Zeitabständen abzurufen und bei Bedarf auf eigenen Speichermedien zu sichern und zu archivieren.<br/><br/>
14. <br/><br/>
<span style={underline}>„Healing the World with human touch“ ist ein Online-Dienst</span><br/><br/>

„Healing the World with human touch“ ist ein Online-Dienst und kommuniziert mit seinen Nutzern entweder über seine Fragebögen oder per E-Mail.<br/><br/>
Telefon-, Fax- und Post-Kommunikation wird ermöglicht, sofern sich dafür im Einzelfall ein Erfordernis ergibt.<br/><br/>
15.<br/><br/>
<span style={underline}>Kooperationen mit Dritten</span><br/><br/>
Wir wollen auch in jeder Hinsicht unabhängig bleiben, so dass wir um Verständnis bitten, dass wir nicht als „Partner“ von anderen Initiativen etc. auftreten möchten und von anderen Initiativen auch nicht als „Partner“ bezeichnet werden möchten.<br/><br/>
Ob und inwieweit mit anderen Initiativen eine sinnvolle Kooperation möglich ist, die mit unseren Grundsätzen und Richtlinien vereinbar wäre, das würden wir im Einzelfall prüfen. <br/><br/>
16.<br/><br/>
<span style={underline}>Nutzungs- und Urheberrechte</span><br/><br/>
„Healing the World with human touch“ ist eine geschützte Marke und ist im Verhältnis zu allen Dritten alleiniger Rechtsinhaber der Vervielfältigungs-, Verbreitungs-, Verarbeitungs- und sämtlicher Urheberrechte sowie des Rechts der unkörperlichen Übertragung und Wiedergabe der „Healing the World with human touch“ -Webseite sowie der einzelnen in ihr enthaltenen Inhalte. <br/><br/>
Die Nutzung sämtlicher Programme und der darin enthaltenen Inhalte, Materialien sowie Marken- und Handelsnamen ist ausschließlich zu den in diesen Allgemeinen Nutzungsbedingungen genannten Zwecken zulässig.<br/><br/>
17.<br/><br/>
<span style={underline}>Anwendbares Recht, Alternative Streitbeilegung</span><br/><br/>
Dieser Vertrag unterliegt dem Recht der Bundesrepublik Deutschland unter Ausschluss deutschen Kollisionsrechts. <br/><br/>
Bei Nutzern, die Verbraucher mit Wohnsitz in der Europäischen Union sind, kann ggf. auch das Recht desjenigen Landes, in dem sie Ihren Wohnsitz haben, zur Anwendung kommen, sofern es sich um zwingende Bestimmungen handelt. <br/><br/>
Die EU-Kommission bietet die Möglichkeit zur Online-Streitbeilegung auf einer von ihr betriebenen Online-Plattform. <br/><br/>
Diese Plattform ist über den externen Link <br/><br/>
<a href="http://ec.europa.eu/consumers/odr/" target="_blank" rel="noreferrer">http://ec.europa.eu/consumers/odr/</a> <br/><br/>
zu erreichen. <br/><br/>
„Healing the world with human touch“ nimmt nicht an Streitbeilegungsverfahren vor Verbraucherschlichtungsstellen teil. Insofern besteht keine Rechtspflicht.<br/><br/>
18.<br/><br/>
<span style={underline}>Sonstige Bestimmungen</span><br/><br/>

Sofern für die Nutzer dadurch keine Nachteile entstehen, ist „Healing the world with human touch“ im Rahmen seiner Dienstleistungen und der geltenden gesetzlichen Bestimmungen auch berechtigt, dritte Dienstleister und Erfüllungsgehilfen mit der Erbringung von Teilen des Leistungsspektrums zu beauftragen. <br/><br/>
Sollte eine Bestimmung dieser Allgemeinen Nutzungsbedingungen unwirksam sein oder werden oder sollten die Allgemeinen Nutzungsbedingungen unvollständig sein, so wird die Gültigkeit der Allgemeinen Nutzungsbedingungen in seinem übrigen Inhalt davon nicht berührt. Die gesetzlichen Bestimmungen gelten in diesem Falle ergänzend.<br/><br/>
Die Sprache, in der „Healing the world with human touch“ mit seinen Nutzern kommuniziert, ist deutsch, wobei wir uns im Rahmen unserer Möglichkeiten auch darum bemühen werden, mit englischsprachigen Nutzern auch in englischer Sprache zu kommunizieren. <br/><br/>
Jeder Nutzer kann die aktuellen Allgemeinen Nutzungsbedingungen jederzeit kostenlos herunterladen.<br/><br/>
Verantwortlich für den Inhalt der Leistungen von „Healing the world with human touch“ ist „Healing the world with human touch“, vertreten durch ihre Geschäftsführerin Eleonore Fischer, diese ggf. vertreten durch ihren bevollmächtigten Rechtsanwalt.<br/><br/>
„Healing the world with human touch“ behält sich vor, den Inhalt seiner Allgemeinen Nutzungsbedingungen und den Inhalt seiner Leistungen und Angebote jederzeit zu ändern. Voraussetzung ist hierfür, dass die Änderung auf eine veränderte Gesetzeslage, eine Behördenentscheidung oder Rechtsprechung oder auf neue Erfahrungen und Erkenntnisse in der Verwaltung von „Healing the world with human touch“ zurückzuführen ist. <br/><br/>
Auch Änderungen rein technischer oder prozessualer Natur oder Sicherheitslücken berechtigen „Healing the world with human touch“  dazu, die Leistungen von „Healing the world with human touch“ zu verändern oder zu beenden. <br/><br/>


<span style={bold}>!! Nun wünschen wir Ihnen viel Freude und Erfolg mit „Healing the world with human touch“ !!<br/><br/>

Euer Healing the world with human touch – Team</span>
        </div>
        <Footer />
      </>
    );
  }

export default Agb