import { useTranslation } from "react-i18next";
import { DBTables } from "../../enums/dbTables";

const KategorieWahlForm = props => {

    const {setDbTable} = props
    const {t} = useTranslation('common')

    return (
        <div className="mb-3 mt-3">
                <label htmlFor="category" className="form-label">{t('neu.Kategorie')}*</label>
                <select className="form-select form-control-lg" defaultValue={DBTables.Keiner}  id="category" name="category" onChange={e => setDbTable(e.target.value)}>
                    <option value={DBTables.Keiner}></option>
                    <option value={DBTables.ArbeitAngebote}>{t('home.AngeboteVonArbeitgebern')}</option>
                    <option value={DBTables.ArbeitGesuche}>{t('home.GesucheVonArbeitnehmern')}</option>
                    <option value={DBTables.Partner}>{t('home.Partner')}</option>
                    <option value={DBTables.Gastronomie}>{t('home.Gastronomie')}</option>
                    <option value={DBTables.Kinder}>{t('home.Kinder')}</option>
                    <option value={DBTables.Kultur}>{t('home.Kultur')}</option>
                    <option value={DBTables.Helfen}>{t('home.Helfen')}</option>
                    <option value={DBTables.HeilenSchulmedizin}>{t('home.HeilenSchul')}</option>
                    <option value={DBTables.HeilenNatur}>{t('home.HeilenNatur')}</option>
                    <option value={DBTables.HeilenSpirit}>{t('home.HeilenSpirit')}</option>
                    <option value={DBTables.Shopping}>{t('home.Shopping')}</option>
                    <option value={DBTables.WohnenVermieter}>{t('home.WohnenVermieter')}</option>
                    <option value={DBTables.WohnenMieter}>{t('home.WohnenMieter')}</option>
                    <option value={DBTables.Freizeit}>{t('home.Freizeit')}</option>
                    <option value={DBTables.Freunde}>{t('home.Freunde')}</option>
                    <option value={DBTables.StartUps}>{t('home.StartUps')}</option>
                    <option value={DBTables.Mitfahrgelegenheit}>{t('home.Mitfahrgelegenheit')}</option>
                    <option value={DBTables.KritischeAnwaelte}>{t('home.KritischeAnwaelte')}</option>
                    <option value={DBTables.Handwerk}>{t('home.Handwerk')}</option>
                    <option value={DBTables.AutarkesLeben}>{t('home.AutarkesLeben')}</option>
                </select>
        </div>
    );
}

export default KategorieWahlForm