import { useTranslation } from "react-i18next";

const Captcha = props => {

    const {captchaAsk, setCaptchaResult} = props
    const {t} = useTranslation('common')
  
    return (
            <div className="mb-3 mt-3">
                <label htmlFor="captcha" className="form-label" >{t('neu.WievielIst')} {captchaAsk.num1} + {captchaAsk.num2} ?</label>
                <input type="text" className="form-control" id="captcha" name="captcha" onChange={e => setCaptchaResult(e.target.value)}/>
            </div>
    );
  }
  
  export default Captcha