import { useTranslation } from "react-i18next";
import Footer from "../footer/Footer";
import Header from "../header/Header";

const Impressum = () => {

    const {t} = useTranslation('common')

    return (
        <>
        <div className='container'>
            <Header />
            <h4 className="display-6 mb-4" style={{textAlign: 'center', fontSize: '1.7em'}}>{t('impressum.Ueberschrift')}</h4>
            <p>
            {t('impressum.Beschreibung1')}<br/>
            {t('impressum.Beschreibung2')}<br/>
            {t('impressum.Beschreibung3')}<br/>
            {t('impressum.Beschreibung4')}<br/>
            {t('impressum.Beschreibung5')}
            </p>
            <p>
            {t('impressum.Beschreibung6')}<br/>
            {t('impressum.Beschreibung7')}<br/> 
            {t('impressum.Beschreibung8')}<br/>
            {t('impressum.Beschreibung9')}
            </p>
        </div>
        <Footer />
      </>
    );
  }

export default Impressum