import { Languages } from "./enums/languages"

export const inputRegex = {
    plz: /^[0-9]{1,6}$/,
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    pflichtfeld_max50: /^.{1,50}$/,
    pflichtfeld_max100: /^.{1,100}$/,
    pflichtfeld_max200: /^.{1,200}$/,
    pflichtfeld_max1000: /^.{1,1000}$/s,
    optionalesFeld: /^.{0,50}$/
}

export const validateInput = (input, regex) => {
    return input.match(regex)
}

export const validateCaptcha = (input, num1, num2) => {
    return parseInt(input) === parseInt(num1) + parseInt(num2)
}

export const getApiString = (language) => {

    let apiString
    switch (language) {
        case Languages.DE:
            apiString = process.env.REACT_APP_BASE_URL
            break;
        case Languages.EN:
            apiString = process.env.REACT_APP_ENG_API
            break;
        default:
            break;
    }

    return apiString
}
