import { useTranslation } from "react-i18next";
import { WohnenCategories } from "../../enums/wohnenCategoties";
import ButtonBig from "../general/ButtonBig";

const GrundauswahlWohnen = props => {

    const {setWohnenCategory} = props
    const {t} = useTranslation('common')

    return (<>
                <ButtonBig clickHandler={() => setWohnenCategory(WohnenCategories.Vermieter)} buttonText={t('home.WohnenVermieter')}/>
                <br />
                <ButtonBig clickHandler={() => setWohnenCategory(WohnenCategories.Mieter)} buttonText={t('home.WohnenMieter')}/>
            </>);
}

export default GrundauswahlWohnen