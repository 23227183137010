import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BundeslaenderDeutschland, BundeslaenderLuxemburg, BundeslaenderOesterreich, BundeslaenderSchweiz, BundeslaenderOstbelgien } from "../../enums/bundeslaender";
import { Laender } from "../../enums/laender";

const LaenderwahlForm = props => {

    const {form, setForm} = props
    const {t} = useTranslation('common')

    const changeHandler = e => {
        // setForm(e.target.value)
        if(e.target.value === Laender.Deutschland){
            setForm({...form, land: Laender.Deutschland, bundesland: BundeslaenderDeutschland.BadenWuerttemberg})
            return
        }
        if(e.target.value === Laender.Oesterreich){
            setForm({...form, land: Laender.Oesterreich, bundesland: BundeslaenderOesterreich.Burgenland})
            return
        }
        if(e.target.value === Laender.Schweiz){
            setForm({...form, land: Laender.Schweiz, bundesland: BundeslaenderSchweiz.Zuerich})
            return
        }
        if(e.target.value === Laender.Luxemburg){
            setForm({...form, land: Laender.Luxemburg, bundesland: BundeslaenderLuxemburg.Capellen})
            return
        }
        if(e.target.value === Laender.Ostbelgien){
            setForm({...form, land: Laender.Ostbelgien, bundesland: BundeslaenderOstbelgien.Ostbelgien})
            return
        }
    }

    useEffect(() => {
        setForm({...form, land: Laender.Deutschland, bundesland: BundeslaenderDeutschland.BadenWuerttemberg})
        // eslint-disable-next-line
    }, [])

    return (
        <div className="mb-3 mt-3">
                <label htmlFor="land" className="form-label">{t('neu.Land')}*</label>
                <select className="form-select form-control-lg" defaultValue={Laender.Deutschland}  id="land" name="land" onChange={e => changeHandler(e)}>
                    <option value={Laender.Deutschland}>Deutschland</option>
                    <option value={Laender.Oesterreich}>Österreich</option>
                    <option value={Laender.Schweiz}>Schweiz</option>
                    <option value={Laender.Luxemburg}>Luxemburg</option>
                    <option value={Laender.Ostbelgien}>Ostbelgien</option>
                </select>
        </div>
    );
}

export default LaenderwahlForm
