import { useTranslation } from "react-i18next";
import {Link} from "react-router-dom";
import ButtonSpenden from "../general/ButtonSpenden";
import gliderKlein from './glider_klein.png'

const allOther = {
  textAlign: 'center',
  paddingTop: '10px'
}

const linkStyle = {
  textDecoration: 'none',
  color: 'black'
}

const doNothing = () => {
  // does nothing
}

const d = new Date();
const year = d.getFullYear();

const Footer = () => {

    const {t} = useTranslation('common')

    return (<footer className="bg-light text-center text-lg-start" style={{marginTop: '100px'}}>
              <div className="container p-4" style={{textAlign: 'center'}}>
                <div className="row">
                  <div className="col mb-3 mb-md-0" >
                    <h6><Link to="/spenden" style={linkStyle}><ButtonSpenden clickHandler={doNothing} buttonText={t('footer.Spenden')}/></Link></h6>
                  </div>
                  <div className="col mb-md-0" style={allOther}>
                    <h6><Link to="/unserteam" style={linkStyle}>{t('footer.UnserTeam')}</Link></h6>
                  </div>
                  <div className="col mb-3 mb-md-0" style={allOther}>
                    <h6><Link to="/feedback" style={linkStyle}>{t('footer.Resonanz')}</Link></h6>
                  </div>
                  <div className="col mb-3 mb-md-0" style={allOther}>
                    <h6><Link to="/agb" style={linkStyle}>{t('footer.AGB')}</Link></h6>
                  </div>
                  <div className="col mb-3 mb-md-0" style={allOther}>
                    <h6><Link to="/datenschutz" style={linkStyle}>{t('footer.Datenschutz')}</Link></h6>
                    </div>
                  <div className="col mb-3 mb-md-0" style={allOther}>
                    <h6><Link to="/impressum" style={linkStyle}>{t('footer.Impressum')}</Link></h6>
                  </div>
                </div>
              </div>
              <div className="text-center p-3" style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
                <a href='http://www.catb.org/hacker-emblem/' target="_blank" rel="noreferrer">
                  <img src={gliderKlein} alt='hacker emblem' style={{width:'20px', height:'20px'}}/></a> {year}
              </div>
            </footer>);    
  }
  
export default Footer