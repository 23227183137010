export const BundeslaenderDeutschland = {
    BadenWuerttemberg: 'BadenWuerttemberg',
    Bayern: 'Bayern',
    Berlin: 'Berlin',
    Brandenburg: 'Brandenburg',
    Bremen: 'Bremen',
    Hamburg: 'Hamburg',
    Hessen: 'Hessen',
    MecklenburgVorpommern: 'MecklenburgVorpommern',
    Niedersachsen: 'Niedersachsen',
    NordrheinWestfalen: 'NordrheinWestfalen',
    RheinlandPfalz: 'RheinlandPfalz',
    Saarland: 'Saarland',
    Sachsen: 'Sachsen',
    SachsenAnhalt: 'SachsenAnhalt',
    SchleswigHolstein: 'SchleswigHolstein',
    Thueringen: 'Thueringen'
};

export const BundeslaenderOesterreich = {
  Burgenland: 'Burgenland',
  Kaernten: 'Kaernten',
  Niederoesterreich: 'Niederoesterreich',
  Oberoesterreich: 'Oberoesterreich',
  Salzburg: 'Salzburg',
  Steiermark: 'Steiermark',
  Tirol: 'Tirol',
  Vorarlberg: 'Vorarlberg',
  Wien: 'Wien'
}

export const BundeslaenderSchweiz = {
  Zuerich: 'Zuerich',
  Bern: 'Bern',
  Luzern: 'Luzern',
  Uri: 'Uri',
  Schwyz: 'Schwyz',
  Obwalden: 'Obwalden',
  Nidwalden: 'Nidwalden',
  Glarus: 'Glarus',
  Zug: 'Zug',
  Freiburg: 'Freiburg',
  Solothurn: 'Solothurn',
  BaselStadt: 'BaselStadt',
  BaselLandschaft: 'BaselLandschaft',
  Schaffhausen: 'Schaffhausen',
  AppenzellARh: 'AppenzellARh',
  AppenzellIRh: 'AppenzellIRh',
  SanktGallen: 'SanktGallen',
  Graubuenden: 'Graubuenden',
  Aargau: 'Aargau',
  Thurgau: 'Thurgau',
  Tessin: 'Tessin',
  Waadt: 'Waadt',
  Wallis: 'Wallis',
  Neuenburg: 'Neuenburg',
  Genf: 'Genf',
  Jura: 'Jura'
};

export const BundeslaenderLuxemburg = {
  Capellen: 'Capellen',
  EschAnDerAlzette: 'EschAnDerAlzette',
  Luxemburg: 'Luxemburg',
  Mersch: 'Mersch',
  Clerf: 'Clerf',
  Diekirch: 'Diekirch',
  Redingen: 'Redingen',
  Vianden: 'Vianden',
  Wiltz: 'Wiltz',
  Echternach: 'Echternach',
  Grevenmacher: 'Grevenmacher',
  Remich: 'Remich'
};

export const BundeslaenderOstbelgien = {
  Ostbelgien: 'DeutschsprachigeGemeinschaftInOstbelgien'
};
export const BundeslaenderUSA = {
  Alaska: 'Alaska',
  Texas: 'Texas',
  California: 'California',
  Montana: 'Montana',
  NewMexico: 'NewMexico',
  Arizona: 'Arizona',
  Nevada: 'Nevada',
  Colorado: 'Colorado',
  Oregon: 'Oregon',
  Wyoming: 'Wyoming',
  Michigan: 'Michigan',
  Minnesota: 'Minnesota',
  Utah: 'Utah',
  Idaho: 'Idaho',
  Kansas: 'Kansas',
  Nebraska: 'Nebraska',
  SouthDakota: 'SouthDakota',
  Washington: 'Washington',
  NorthDakota: 'NorthDakota',
  Oklahoma: 'Oklahoma',
  Missouri: 'Missouri',
  Florida: 'Florida',
  Wisconsin: 'Wisconsin',
  Georgia: 'Georgia',
  Illinois: 'Illinois',
  Iowa: 'Iowa',
  NewYork: 'NewYork',
  NorthCarolina: 'NorthCarolina',
  Arkansas: 'Arkansas',
  Alabama: 'Alabama',
  Louisiana: 'Louisiana',
  Mississippi: 'Mississippi',
  Pennsylvania: 'Pennsylvania',
  Ohio: 'Ohio',
  Virginia: 'Virginia',
  Tennessee: 'Tennessee',
  Kentucky: 'Kentucky',
  Indiana: 'Indiana',
  Maine: 'Maine',
  SouthCarolina: 'SouthCarolina',
  WestVirginia: 'WestVirginia',
  Maryland: 'Maryland',
  Hawaii: 'Hawaii',
  Massachusetts: 'Massachusetts',
  Vermont: 'Vermont',
  NewHampshire: 'NewHampshire',
  NewJersey: 'NewJersey',
  Connecticut: 'Connecticut',
  Delaware: 'Delaware',
  RhodeIsland: 'RhodeIsland',
  DistrictOfColumbia: 'DistrictOfColumbia'
}

