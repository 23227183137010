import { useTranslation } from "react-i18next";

const FeedbackCard = props => {

    const {feedback} = props
    const {t} = useTranslation('common')

    return (
      <>
        <div className="card mb-1 bg-light" >
          <div className="card-body">
            <h4 className="card-title">{feedback.autor} {t('feedback.SchriebUns')}:</h4>
            <p className="card-text">{feedback.inhalt}</p>
          </div>
        </div>
      </>
    );
  }
  
  export default FeedbackCard