import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PageElement } from "../../../enums/pageElement";
import { Success } from "../../../enums/success";
import ErrorAlert from "../../alerts/ErrorAlert";
import SuccessAlert from "../../alerts/SuccessAlert";
import Button from "../../general/Button";
import MailBackFormLogic from "../../general/MailBackFormLogic";

const AngebotDetail = props => {

    const {detailAngebot, setShowPageElement, dbTable} = props

    const [success, setSuccess] = useState(Success.NothingYet)
    const [showMailBack, setShowMailBack] = useState(false)
    const {t} = useTranslation('common')

    const openMailBack = () => {
        setShowMailBack(!showMailBack)
        setSuccess(Success.NothingYet)
    }
  
    return (
            <>
                <Button buttonText={t('home.Zurueck')} setShowPageElement={setShowPageElement}  whereTo={PageElement.Start}/>
                <br /><br />
                <div className="card">
                    <div className="card-header" style={{textAlign: 'center', fontWeight: 'bold'}}>{detailAngebot.ueberschrift}</div>
                    <div className="card-body">
                        {detailAngebot.anzeigentext} <br /><br />
                        {detailAngebot.webseite && <span>{t('home.Webseite')}: {detailAngebot.webseite}<br /></span>} 
                        {t('home.PLZ')} / {t('home.Ort')}: {detailAngebot.plz} {detailAngebot.ort}
                    </div>
                </div>
                <br />
                <div className="d-grid">
                    <button className="btn btn-secondary btn-block" onClick={openMailBack}>{t('home.MeldenInserat')}</button><br/><br/>
                </div>
                {showMailBack && <MailBackFormLogic dbTable={dbTable} id={detailAngebot.id} anzeigenUeberschrift={detailAngebot.ueberschrift} setShowMailBack={setShowMailBack} success={success} setSuccess={setSuccess}/>}
                {success === Success.No && <ErrorAlert message={t('alerts.ServerError')} />}
                {success === Success.Yes && <SuccessAlert message={t('alerts.SuccessMail')}/>}
            </>
        );
  }
  
  export default AngebotDetail
  