import { BundeslaenderOesterreich } from "../../enums/bundeslaender";

const MenuOesterreich = props => {

    const {bundesland, setBundesland} = props

    return (
         <div className="mb-3 mt-3">
                <select className="form-select form-control-lg" defaultValue={bundesland} id="bundesland" name="bundesland" onChange={e => setBundesland(e.target.value)}>
                    <option value={BundeslaenderOesterreich.Burgenland}>Burgenland</option>
                    <option value={BundeslaenderOesterreich.Kaernten}>Kärnten</option>
                    <option value={BundeslaenderOesterreich.Niederoesterreich}>Niederösterreich</option>
                    <option value={BundeslaenderOesterreich.Oberoesterreich}>Oberösterreich</option>
                    <option value={BundeslaenderOesterreich.Salzburg}>Salzburg</option>
                    <option value={BundeslaenderOesterreich.Steiermark}>Steiermark</option>
                    <option value={BundeslaenderOesterreich.Tirol}>Tirol</option>
                    <option value={BundeslaenderOesterreich.Vorarlberg}>Vorarlberg</option>
                    <option value={BundeslaenderOesterreich.Wien}>Wien</option>
                </select>
         </div>
    );
}

export default MenuOesterreich