import { DBTables } from '../../enums/dbTables';
import { HeilenCategories } from '../../enums/heilenCategories';
import Inserate from '../arbeit/Inserate';
import GrundauswahlHeilen from './GrundauswahlHeilen';

const GrundauswahlHeilenLogic = props => {

    const {plz, setPlz, heilenCategory, setHeilenCategory, land, setLand, bundesland, setBundesland, eingangstext} = props

    return (<>
                {heilenCategory === HeilenCategories.Startseite && <><br /><div className="card">
                    <div className="card-body" style={{textAlign: 'center', fontSize: '1.0em',backgroundColor: 'rgb(236, 236, 236)'}}>{eingangstext}</div>
                </div><br /></>}
                {heilenCategory === HeilenCategories.Startseite && <GrundauswahlHeilen setHeilenCategory={setHeilenCategory}/>}
                {heilenCategory === HeilenCategories.Schul && <Inserate plz={plz} setPlz={setPlz} land={land} setLand={setLand} bundesland={bundesland} setBundesland={setBundesland} dbTable={DBTables.HeilenSchulmedizin} eingangstext={eingangstext}/>}
                {heilenCategory === HeilenCategories.Natur && <Inserate plz={plz} setPlz={setPlz} land={land} setLand={setLand} bundesland={bundesland} setBundesland={setBundesland} dbTable={DBTables.HeilenNatur} eingangstext={eingangstext}/>}
                {heilenCategory === HeilenCategories.Spirit && <Inserate plz={plz} setPlz={setPlz} land={land} setLand={setLand} bundesland={bundesland} setBundesland={setBundesland} dbTable={DBTables.HeilenSpirit} eingangstext={eingangstext}/>}
            </>)
}

export default GrundauswahlHeilenLogic