import Footer from "../footer/Footer";
import Header from "../header/Header";

const AgbUSA = () => {

    const underline = {
      textDecoration: 'underline'
    }

    const bold = {
      fontWeight: 'bold'
    }

    return (
      <>
        <div className='container'>
            <Header />
<h4 className="display-6 mb-4" style={{textAlign: 'center', fontSize: '1.7em'}}>Our General Terms of Use <br/><br/>
General terms and conditions for the use of "Healing the World with human touch" in Germanspeaking
countries (Germany / Austria / German-speaking Switzerland)<br/><br/>
Status: December 2021<br/><br/>
I.<br/><br/>
Introduction</h4>
In this respect, too, it is probably time for a "turn of the times", in that we should once again trust
the people who contact us in their ability to act responsibly and considerately, which politics has
increasingly denied them in recent years.<br/><br/>
Does the responsible and honest 1000 x 1000 person really need laws and guidelines so that he
knows how to behave properly?<br/><br/>
According to our impression, the freedom of man is already threatened when he always has to read
in some written text so that he knows what he is supposedly still allowed to do and what he is no
longer allowed to do.<br/><br/>
In the <span style={bold}>"Gospel of Peace of the Essenes"</span> (see among others: Writings of the Essenes, Book 1, ISBN-10:
3890601278, ISBN-13: 978-3890601274) it says among other things (quote).<br/><br/>
"[...] They sat round about Jesus and asked him, "Master, what are the laws of life? Stay longer with
us and teach us. We want to listen to your words, so that we may be healed and made righteous."
And Jesus answered, "Seek not the law in your holy scriptures: for the life is the law, but the
scriptures are dead. ... For truly I say to you, everything that lives is closer to God than the Scriptures,
which are without life. God created life and everything that lives so that they might teach man the
laws of the true Godhead through the ever-living Word. God did not write the laws in the pages of
books, but in your heart and in your spirit. They are in your breath, your blood, your bones, in your
flesh, your bowels, your eyes, your ears, and in every tiny particle of your body. They are
omnipresent in the air, in the water, in the earth, in the plants, in the rays of the sun, in the depths
and in the heights. They all speak to you so that you may understand the Word and the Will of the
living Godhead..."<br/><br/>
So people who act responsibly and considerately - we believe - do not need to be lectured in detail
about what they should or should not do. <span style={bold}>They know in their hearts what is "right" and "wrong".</span>
We want to offer a platform to people who can deal with each other in a respectful, fair, objective
and level-headed way.<br/><br/>
It must be self-evident for every user of our platform that he or she only makes truthful statements
to us and third parties and that his or her language is and remains factual, friendly and respectful.
We will not offer a platform to anyone who does not observe these self-evident principles because
he or she sends us or the users of our website by e-mail or by other means texts or other messages
that are unobjective, insinuating, harassing, racist or for other reasons could trigger claims for
injunctive relief under civil law or are even of criminal relevance.<br/><br/>
We will not publish such advertisements on our homepage.<br/><br/>
Anyone who knowingly and willingly makes untrue statements to users of our platform, which must
therefore trigger a misconception in the third party, may be liable to prosecution for fraud or other
offences, not to mention the emotional fraud and damage that is thereby realised.<br/><br/>
Based on our own findings and numerous suggestions, we have finally repositioned ourselves in
every respect with this platform "Healing the World with human touch": clearer, more broadly
positioned, and with the maximum possible level of security.<br/><br/>
We would like to make it explicitly clear that we have no insight into the correspondence and data
that advertisers and interested parties exchange with each other. <span style={bold}>All mails do not go through our
platform, but directly from account to account. These correspondences are also none of our
business.</span><br/><br/>
We do not assume any liability for the correctness of the information that advertisers post on our
platform. Every user of our platform is requested to question the accuracy of this information in
contact with the advertiser if he has doubts about the accuracy. The same applies in reverse, of
course, to advertisers who are contacted by interested parties.<br/><br/>
In particular, no one should give out their personal contact details, such as address and mobile phone
number, to people they have contacted via our platform. The best protection is utmost attentiveness
and precaution, and a healthy distrust and reasonable control - for instance also via a Zoom
conference in the context of a first direct contact - is always better than blind trust in the integrity of
persons who may only make nice words.<br/><br/>
Without a certain basic trust, we cannot communicate with each other and build networks. So please
do not destroy this trust in the people who place it in you.<br/><br/>
Our services are provided as a service to society and for the purpose of networking people on a
voluntary and honorary basis. For this reason, no one can make an enforceable claim to our services
or to the maintenance of our individual sections, even though we always want to make every effort
to meet all advertisement requests.<br/><br/>
Within the framework of our project, we are also unable to offer legal advice or life assistance. Our
platform is the help we want to and can offer to society.<br/><br/>
We will treat all incoming data confidentially and collect, process and publish it in accordance with
the applicable data protection regulations. For more information, please refer to our separate data
protection statement.<br/><br/>
As the world is currently not yet able to get by without detailed regulations, since in these times it is
precisely not only friendly-minded people who are on the move, we have regulated our General
Terms of Use in detail below.<br/><br/>
<h4 className="display-6 mb-4" style={{textAlign: 'center', fontSize: '1.7em'}}>II.<br/><br/>
Our General Terms of Use in detail</h4><br/><br/>
These General Terms and Conditions apply to the relationship between "Healing the World with human touch" and its users, whether you access "Healing the World with human touch" through the website or mobile apps.<br/><br/>
1.<br/><br/>
<span style={underline}>Services provided by Healing the World with human touch</span><br/><br/>
The service offered by "Healing the World with human touch" enables advertisers to publish their advertisements free of charge on our platform in the categories provided by us:<br/><br/>
Work<br/>
Partner<br/>
Gastronomy<br/>
Children<br/>
Shopping<br/>
Living<br/>
Culture<br/>
Leisure<br/>
Help<br/>
Friends<br/>
Healing (divided into: Conventional Medicine / Naturopathy / Spirituality)<br/>
Startups<br/>
Carpooling<br/>
Critical Advocates<br/>
Craft<br/><br/>
For the collection of data from all users, "Healing the World with human touch" provides contact forms (data collection masks) under the above-mentioned headings, which are filled in with data by the users. <br/><br/>
The input fields of these contact forms indicate which data is collected in the respective context. Of this data, we will only publish the text of the advertisement and the email account so that users of our platform can inform themselves about the content of their advertisement and contact you. <br/><br/>
We will not publish the names and addresses of the advertisers, unless this serves the purpose of the advertisements, e.g. in the category "gastronomy". <br/><br/>
But we need this data so that we can recognise with whom we are corresponding and who is responsible for the data transmitted to us. In doing so, however, we proceed according to the principle "Only what we need at least to be able to communicate with each other." <br/><br/>
We will delete all data transmitted by advertisers if they request us to delete their advertisement, for example because the purpose of their advertisement has been fulfilled or because there is no longer any interest in the continued existence of the advertisement. <br/><br/>
Furthermore, all advertisements and data will be automatically deleted at the intervals specified below. <br/><br/>
2.<br/><br/>
<span style={underline}>Where is "Healing the World with human touch" active?</span><br/><br/>
We initially offer our services in all German-speaking countries (Germany / Austria / German-speaking Switzerland), so that we enable regional and supra-regional search and contact in each of these countries. However, we are also happy to publish - as far as possible - advertisements that relate to contact requests outside the German-speaking countries. <br/><br/>
The respective advertiser's profile is visible in all German-speaking countries. We reserve the right to provide a comparable offer in English for English-speaking countries in due course. Offers in all other world languages (Spanish, Portuguese, French etc.) may also be possible at some point. <br/><br/>
On January, the 20the, we have started in the USA. <br/><br/>
3.<br/><br/>
<span style={underline}>What does "Healing the World with human touch" want?</span><br/><br/>
"Healing the World with human touch" is a platform for building social networks and therefore wants to offer all users the possibility to present themselves to third parties according to their own wishes and ideas and to establish contacts with third parties.<br/><br/>
4.<br/><br/>
<span style={underline}>Data transmission / confirmation e-mail / cooperation relationship</span><br/><br/>
The placement of an advertisement requires the transmission of the advertiser's data.<br/><br/>
The prerequisite for this is the complete transmission of all compulsory contents, which are
requested by our contact forms.<br/><br/>
With the receipt of the data, a free and purely voluntary cooperation relationship is initially
established between "Healing the World with human touch" and the user contacting us, which is
governed by the provisions of these General Terms and Conditions.<br/><br/>
5.<br/><br/>
<span style={underline}>Financing of "Healing the World with human touch</span><br/><br/>
"Healing the World with human touch" is financed by donations from those who wish to support the
networking work of "Healing the World with human touch".<br/><br/>
"Healing the World with human touch" provides its services free of charge to both advertisers and
those interested in the advertisements published on its platform.<br/><br/>
However, "Healing the World with human touch" reserves the right to make its services or individual
components of its services available only against payment at some point after prior announcement,
either because the further maintenance of the advertisement requests would result in such a large
(cost) effort that "Healing the World with human touch" would have to build up new administrative
structures in order to be able to absorb this effort, or because this seems appropriate to us for other
reasons.<br/><br/>
However, such a cost obligation is only foreseen for the mediation of real estate to buyers.<br/><br/>
6.<br/><br/>
<span style={underline}>The account of Healing the World with human touch</span><br/><br/>
The bank account of Connecting Life is indicated on the homepage under the link "Donations".<br/><br/>
<span style={bold}>Please indicate "donation" as the intended purpose on your bank transfer to us.</span><br/><br/>
We would like to thank all supporters of our platform in advance. Without your donation our work
would not be possible.<br/><br/>
7.<br/><br/>
<span style={underline}>The work of the "Healing the World with human touch" team</span><br/><br/>
Our team strives to provide our services seven days a week.<br/><br/>
However, the provision of our services always depends on when the members of our team find the
time to devote themselves to the voluntary maintenance of the data of "Healing the World with
human touch" in addition to their other daily obligations.<br/><br/>
However, we have optimised our portal to such an extent that we can now check the serious content
of a very large number of daily advertisements very quickly, even with very little work, and then
publish them.<br/><br/>
We ask for your patience if - as in phases of particularly strong response - we need a little time to
upload the advertisements.<br/><br/>
8.<br/><br/>
<span style={underline}>Liability issues</span><br/><br/>
"Healing the World with human touch" does not assume any warranty or liability for the
uninterrupted operation or the proper usability or accessibility of its services at any time.<br/><br/>
In particular, "Healing the World with human touch" accepts no liability in the event of disruption or
loss of service due to force majeure or other events beyond the control of "Healing the World with
human touch".<br/><br/>
Finally, "Healing the World with human touch" is not liable in the event that third parties obtain
knowledge of a user's personal data without authorisation or in a punishable manner, e.g. through a
"hacker attack".<br/><br/>
In such cases of hacking, "Healing the World with human touch" will advise all affected persons to
immediately file a criminal complaint with the competent prosecution authorities in their own name
and to file criminal charges, and will also file such criminal complaints in their own name.
However, we have set ourselves up technically in such a way that such hacker attacks should be
hopeless from the outset.<br/><br/>
9.<br/><br/>
<span style={underline}>Protection against misuse of our services</span><br/><br/>
"Healing the World with human touch" always endeavours to protect its users from harassment such
as spam or fraud through feigned interest and feigned helpfulness.<br/><br/>
However, it is the duty of all users to exercise the utmost care at all times and, in particular, not to
give out personal contact details such as their own address or telephone/mobile phone number to
unknown third parties too quickly. Every user should always take "Trust Who" to heart in his or her
own interest.<br/><br/>
If users receive concrete indications of misuse of our platform, including unauthorised use of their
account, or are harassed or insulted in any way by a user of our portal or are not treated correctly for
any other reason, they should contact us immediately by e-mail and inform us of all relevant facts.<br/><br/>
10.<br/><br/>
<span style={underline}>Termination of the cooperation</span><br/><br/>
Every user can ask for the deletion of his advertisement at any time by mail.
A termination is not necessary, as no user is bound to "Healing the World with human touch" by
contractual obligations.<br/><br/>
In other respects, termination of the cooperation may occur in the cases mentioned below under
point 12 (violation of the obligations mentioned there).<br/><br/>
11.<br/><br/>
<span style={underline}>Further exclusions of liability</span><br/><br/>
"Healing the World with human touch" assumes no responsibility for incorrect information provided
by the users (advertisers, readers) of its portal or for the fact that advertisers have assigned
themselves to the appropriate category when submitting their advertisement requests.<br/><br/>
Therefore, we cannot guarantee the correctness of the information provided by the users of our
portal.<br/><br/>
Nor can we guarantee any user a successful placement.<br/><br/>
"Healing the World with human touch" also assumes no liability in the event that users misuse its
services in an inadmissible or illegal manner.<br/><br/>
Healing the World with human touch" cannot be held liable for the misuse of information either,
especially if a user himself has made his data accessible to a third party.<br/><br/>
12.<br/><br/>
<span style={underline}>Duties of the users of "Healing the World with human touch"</span>.<br/><br/>
Within the transmission of the advertisement wishes and the establishment of contact with our
advertisers it is incumbent on all advertisers to make truthful statements.<br/><br/>
The user (advertiser/user) is solely responsible for the content and correctness of his personal data
and he assures the correctness of the information.<br/><br/>
"Healing the worldd with human touch" has no access to or insight into the correspondences and
data exchanged between advertisers and interested parties in the context of their contact by mail.
This data is transmitted directly and exclusively from account to account of the advertisers and
interested parties involved.<br/><br/>
"Healing the World with human touch" reserves the right to initiate criminal and civil proceedings
against users of its portal who intentionally and/or fraudulently provide false information or pretend
to have a false identity.<br/><br/>
The services of "Healing the World with human touch" are intended exclusively for private use, apart
from sections such as "Healing" and "Food" in particular, where self-employed persons and
tradesmen wish to offer their services.<br/><br/>
As a matter of principle, we no longer <span style={underline}>wish to publish</span> advertisement requests for all variants of
"recommendation marketing", unless they really are concrete job offers with clearly recognisable
responsibility (web link with imprint), clear job description / clear job profile and concrete
remuneration offers.<br/><br/>
By using the services of "Healing the World with human touch", the users enter into the obligation
not to use the data of users made accessible to them - beyond the respective concrete contact that
we were able to arrange - for commercial or advertising purposes.<br/><br/>
In particular, it is expressly prohibited to systematically collect the data of users of our portal - by
whatever means - and to use it for purposes other than the purpose pursued by "Healing the World
with human touch" of making contact with advertisers.<br/><br/>
In this context, it is in particular also not permitted to use computer programmes to read out users'
data.<br/><br/>
All users of the services of "Healing the World with human touch" undertake in particular:
Not to transmit or distribute any immoral, obscene, pornographic or right-wing/left-wing radical
content or photos in advertisements or mails to "Healing the World with human touch" or to other
users;<br/><br/>
not to disseminate defamatory, offensive or otherwise illegal information via the portal of "Healing
the World with human touch" or the contacts established via "Healing the World with human touch";
not to threaten or harass other persons or infringe the rights (including personal rights) of third
parties;<br/><br/>
not to upload data containing a virus (infected software) or software or other material that is
protected by copyright, unless the Customer has the rights thereto or has the necessary consents
from the rights holder;<br/><br/>
not intercept or attempt to intercept any e-mails/messages;<br/><br/>
not to send e-mails/messages (including video chat messages) to users for any purpose other than
communication and, in particular, not to promote or offer goods or services to other customers
(except in cases where this is expressly in accordance with the purpose of the respective contact
mediation);<br/><br/>
Not to send spam mails or chain letters;<br/><br/>
All users of the portal of "Healing the World with human touch" are obliged to treat all personal data
of users of "Healing the World with human touch" confidentially and not to make them accessible to
third parties without the consent of their originator. This applies in particular to names, telephone
and fax numbers, home and email addresses and other confidential data and communications of our
users.<br/><br/>
Failure to comply with any of the above obligations may result in the following sanctions:
Request for comments in the event of suspected breaches of contract/law,
Blocking of use until the facts of the case have been clarified (especially in the case of multiple
complaints from other customers),
warning,
restriction/restriction in the use of the services of "Healing the World with human touch", for
example by partial or complete deletion of advertisements.<br/><br/>
Failure to comply with the aforementioned obligations may, in the event of significant violations, also
lead to immediate and, if necessary, permanent termination of the cooperation and may also have
consequences under civil and criminal law.<br/><br/>
If a user culpably violates his or her duty of care in the context of using the services of "Healing the
World with human touch" and thereby triggers civil claims by "Healing the World with human touch",
users or other third parties, he or she is obliged to indemnify "Healing the World with human touch"
against any kind of damages, claims for damages, other claims, lawsuits, lawyer's fees and court costs
that could arise from this culpable violation of the duty of care. This applies in particular to damages
due to the commission of criminal offences such as defamation, insult, violation of personal rights,
due to the failure of services for other customers, a violation of these General Terms and Conditions,
due to the violation of intellectual property or other rights.<br/><br/>
13.<br/><br/>
<span style={underline}>Intermittent automatic deletions and deletion rights</span><br/><br/>
In order to prevent more and more advertisements from accumulating on our portal over the course
of time, which the advertiser has not asked us to delete, even though - for whatever reason - he is no
longer interested in the continued existence of the advertisement, <span style={bold}>we will automatically delete all
advertisements at intervals of 5 months.</span><br/><br/>
In this case, the affected users of our portal must submit their advertisement requests again via our
contact forms.<br/><br/>
"Healing the World with human touch" is thus entitled to delete advertisements and all related data
after 6 months of their publication without further inquiry.<br/><br/>
For this reason, all users are requested to retrieve the messages received on their behalf as well as
the data of their advertisement at regular and reasonable intervals and, if necessary, to save and
archive them on their own storage media.<br/><br/>
14.<br/><br/>
<span style={underline}>"Healing the World with human touch" is an online service</span><br/><br/>
"Healing the World with human touch" is an online service and communicates with its users either
through its questionnaires or by email.<br/><br/>
Telephone, fax and postal communication is made possible if a need for this arises in individual cases.<br/><br/>
15.<br/><br/>
<span style={underline}>Cooperations with third parties</span><br/><br/>
We also want to remain independent in every respect, so we ask for your understanding that we do
not want to act as a "partner" of other initiatives etc., nor do we want to be called a "partner" by
other initiatives.<br/><br/>
Whether and to what extent a meaningful cooperation is possible with other initiatives that would be
compatible with our principles and guidelines is something we would examine on a case-by-case
basis.<br/><br/>
16.<br/><br/>
<span style={underline}>Rights of use and copyright</span><br/><br/>
"Healing the World with human touch" is a protected trademark and is, in relation to all third parties,
the sole legal owner of the reproduction, distribution, processing and all copyrights as well as the
right of incorporeal transmission and reproduction of the "Healing the World with human touch"
website and the individual contents contained therein.<br/><br/>
The use of all programmes and the contents, materials and brand and trade names contained therein
is permitted exclusively for the purposes stated in these General Terms of Use.<br/><br/>
17.<br/><br/>
<span style={underline}>Applicable Law, Alternative Dispute Resolution</span><br/><br/>
This Agreement shall be governed by and construed in accordance with the laws of the Federal
Republic of Germany, excluding German conflict of laws provisions.<br/><br/>
In the case of users who are consumers residing in the European Union, the law of the country in
which they are domiciled may also apply if necessary, provided that the provisions are mandatory.
The EU Commission offers the possibility of online dispute resolution on an online platform operated
by it.<br/><br/>
This platform can be accessed via the external link<br/><br/>
<a href="http://ec.europa.eu/consumers/odr/" target="_blank" rel="noreferrer">http://ec.europa.eu/consumers/odr/</a><br/><br/>
to reach this platform.<br/><br/>
"Healing the world with human touch" does not participate in dispute resolution proceedings before
consumer arbitration boards. In this respect, there is no legal obligation.
18.<br/><br/>
<span style={underline}>Other provisions</span><br/><br/>
Provided that no disadvantages arise for the users as a result, "Healing the world with human touch"
is also entitled, within the scope of its services and the applicable legal provisions, to commission
third party service providers and vicarious agents with the provision of parts of the range of services.
Should any provision of these General Terms of Use be or become invalid or should the General
Terms of Use be incomplete, the validity of the General Terms of Use in its remaining content shall
not be affected thereby. In this case, the statutory provisions shall apply in addition.<br/><br/>
The language in which "Healing the world with human touch" communicates with its users is
German, although we will also endeavour to communicate with English-speaking users in English as
far as possible.<br/><br/>
Every user can download the current General Terms of Use free of charge at any time.<br/><br/>
Responsible for the content of the services of "Healing the world with human touch" is "Healing the
world with human touch", represented by its managing director Eleonore Fischer, who may be
represented by her authorised lawyer.<br/><br/>
"Healing the world with human touch" reserves the right to change the content of its General Terms
of Use and the content of its services and offers at any time. The prerequisite for this is that the
change is due to a changed legal situation, an official decision or jurisdiction or new experience and
knowledge in the administration of "Healing the world with human touch".<br/><br/>
Changes of a purely technical or procedural nature or security vulnerabilities also entitle "Healing the
world with human touch" to change or terminate the services of "Healing the world with human
touch".<br/><br/>
<span style={bold}>!! Now we wish you much joy and success with "Healing the world with human touch" !!!<br/><br/>
Your Healing the world with human touch - Team</span>
        </div>
        <Footer />
      </>
    );
  }

export default AgbUSA