import { useTranslation } from "react-i18next";
import { HeilenCategories } from "../../enums/heilenCategories";
import ButtonBig from "../general/ButtonBig";

const GrundauswahlHeilen = props => {

    const {setHeilenCategory} = props
    const {t} = useTranslation('common')

    return (<>
                <ButtonBig clickHandler={() => setHeilenCategory(HeilenCategories.Schul)} buttonText={t('home.HeilenSchul')}/>
                <br />
                <ButtonBig clickHandler={() => setHeilenCategory(HeilenCategories.Natur)} buttonText={t('home.HeilenNatur')}/>
                <br />
                <ButtonBig clickHandler={() => setHeilenCategory(HeilenCategories.Spirit)} buttonText={t('home.HeilenSpirit')}/>
            </>);
}

export default GrundauswahlHeilen