import { useTranslation } from "react-i18next";
import Footer from "../footer/Footer";
import Header from "../header/Header";

const Spenden = () => {

    const {t} = useTranslation('common')

    return (
        <>
        <div className='container'>
            <Header />
            <h4 className="display-6 mb-4" style={{textAlign: 'center', fontSize: '1.7em'}}>{t('spenden.Ueberschrift')}</h4>
            <p>{t('spenden.Beschreibung1')}<br />
                {t('spenden.Beschreibung2')}<br />
                {t('spenden.Beschreibung3')}
            </p>
            <table className="table table-borderless">
                <tbody>
                  <tr>
                    <td>{t('spenden.Verwendungszweck')}</td>
                    <td>{t('spenden.Schenkung')}</td>
                    
                  </tr>
                  <tr>
                    <td>{t('spenden.Empfaenger')}</td>
                    <td>{t('spenden.ConnectingLife')}</td>
                  </tr>
                  <tr>
                    <td>{t('spenden.Bankverbindung')}</td>
                    <td>{t('spenden.Institut')}<br />
                    {t('spenden.IBAN')}<br />
                    {t('spenden.BIC')}</td>
                  </tr>
                </tbody>
              </table>
        </div>
        <Footer />
      </>
    );
  }

export default Spenden