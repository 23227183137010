import Footer from "../footer/Footer";
import Header from "../header/Header";

const DatenschutzUSA = () => {

    return (
        <>
        <div className='container'>
            <Header />
            <h4 className="display-6 mb-4" style={{textAlign: 'center', fontSize: '1.7em'}}>Privacy Policy</h4>

            <h4 className="display-6 mb-4" style={{textAlign: 'center', fontSize: '1.7em'}}>GENERAL</h4><br/>

            Personal data (hereinafter referred to as "data") are processed by us only as necessary and for
            the purpose of providing a functional and user-friendly website, including its contents and
            the services offered there.<br/>
            In accordance with Art. 4 No. 1. of Regulation (EU) 2016/679, i.e. the Data Protection
            Regulation (hereinafter referred to as "GDPR"), "processing" means any operation or set of
            operations which is performed upon personal data, whether or not by automatic means, such
            as collection, recording, organisation, filing, storage, adaptation or alteration, retrieval,
            consultation, use, disclosure by transmission, dissemination or otherwise making available,
            alignment or combination, restriction, erasure or destruction.<br/>
            With the following data protection declaration, we inform you in particular about the type,
            scope, purpose, duration and legal basis of the processing of personal data, insofar as we
            decide either alone or jointly with others on the purposes and means of the processing. In
            addition, we inform you below about the third-party components we use for optimisation
            purposes and to increase the quality of use, insofar as third parties process data on their own
            responsibility.<br/>
            Our data protection declaration is structured as follows:<br/>
            I. Information about us as the responsible party<br/>
            II. rights of users and data subjects<br/>
            III. Information on data processing<br/><br/>

            <h4 className="display-6 mb-4" style={{textAlign: 'center', fontSize: '1.7em'}}>I. INFORMATION ABOUT US AS THE RESPONSIBLE PARTY</h4>
            The responsible provider of this website in the sense of data protection law is:<br/>
            Healing the World with human touch, Eleonore Fischer, Höhenstr. 158, 41749 Viersen<br/>
            Mail: service@healingtheworldwithhumantouch.org<br/>
            Web: www.healingtheworldwithhumantouch.org<br/>
            c/o lawyer Wilfried Schmitz (legal advisor and representative of Eleonore Fischer and the
            project)<br/>
            De-Plevitz-Strasse 2<br/>
            52538 Selfkant<br/>
            Germany<br/>
            Mail: ra.wschmitz@gmail.com<br/>
            Web: https://www.rechtsanwalt-wilfried-schmitz.de/kontakt/<br/><br/>

            <h4 className="display-6 mb-4" style={{textAlign: 'center', fontSize: '1.7em'}}>II. RIGHTS OF USERS AND DATA SUBJECTS</h4>
            With regard to the data processing described in more detail below, users and data subjects
            have the right to<br/>
            -to confirmation as to whether data concerning them are being processed, to information
            about the data processed, to further information about the data processing and to copies of
            the data (cf. also Art. 15 DSGVO);<br/>
            -correction or completion of inaccurate or incomplete data (cf. also Art. 16 GDPR);<br/>
            -immediate erasure of the data concerning him or her (cf. also Art. 17 GDPR), or, alternatively,
            where further processing is necessary pursuant to Art. 17(3) GDPR, restriction of processing in
            accordance with Art. 18 GDPR;<br/>
            -to receive the data concerning them and provided by them and to transfer this data to other
            providers/controllers (cf. also Art. 20 GDPR);<br/>
            -to lodge a complaint with the supervisory authority if they consider that the data concerning
            them is being processed by the provider in breach of data protection law (cf. also Art. 77
            GDPR).<br/>
            In addition, the provider is obliged to inform all recipients to whom data has been disclosed
            by the provider about any correction or deletion of data or restriction of processing that
            takes place on the basis of Articles 16, 17 (1), 18 DSGVO. However, this obligation does not
            apply if such notification is impossible or involves a disproportionate effort.<br/>
            Notwithstanding this, the user has a right to information about these recipients.<br/>
            Likewise, according to Art. 21 DSGVO, users and data subjects have the right to object to the
            future processing of data concerning them, insofar as the data is processed by the provider in
            accordance with Art. 6 para. 1 lit. f) DSGVO.<br/>
            In particular, an objection to data processing for the purpose of direct advertising is
            permissible.<br/><br/>

            <h4 className="display-6 mb-4" style={{textAlign: 'center', fontSize: '1.7em'}}>III. INFORMATION ON DATA PROCESSING</h4>
            Your data processed when using our website will be deleted or blocked as soon as the
            purpose of the storage no longer applies, the deletion of the data does not conflict with any
            statutory retention obligations and no other information is provided below on individual
            processing procedures.<br/><br/>

            <h4 className="display-6 mb-4" style={{textAlign: 'center', fontSize: '1.7em'}}>SERVER DATA</h4>
            For technical reasons, in particular to ensure a secure and stable Internet presence, data is
            transmitted by your Internet browser to us or to our web space provider. These so-called
            server log files include the type and version of your internet browser, the operating system,
            the website from which you accessed our website (referrer URL), the website(s) of our website
            that you visit, the date and time of the respective access as well as the IP address of the
            internet connection from which our website is used.<br/>
            The data collected in this way is temporarily stored, but not together with other data about
            you.<br/>
            This storage takes place on the legal basis of Art. 6 para. 1 lit. f) DSGVO. Our legitimate
            interest lies in the improvement, stability, functionality and security of our website.
            The data is deleted again after seven days at the latest, unless further storage is required for
            evidence purposes. Otherwise, the data is exempt from deletion in whole or in part until the
            final clarification of an incident<br/><br/>

            <h4 className="display-6 mb-4" style={{textAlign: 'center', fontSize: '1.7em'}}>NEWSLETTER</h4>
            If you register for our free newsletter, the data requested by you for this purpose, i.e. your email address and - optionally - your name and address, will be transmitted to us. At the same
            time, we store the IP address of the Internet connection from which you access our website
            as well as the date and time of your registration. During the further registration process, we
            will ask for your consent to send you the newsletter, describe the content in detail and refer
            to this data protection declaration. We use the data collected in this process exclusively for
            sending the newsletter - in particular, it is therefore not passed on to third parties.<br />
            The legal basis for this is Art. 6 para. 1 lit. a) DSGVO.<br />
            In accordance with Art. 7 (3) DSGVO, you can revoke your consent to receive the newsletter
            at any time with effect for the future. To do so, you simply need to inform us of your
            revocation or click on the unsubscribe link contained in each newsletter.<br/><br/>

            <h4 className="display-6 mb-4" style={{textAlign: 'center', fontSize: '1.7em'}}>CONTACT REQUESTS / CONTACT OPTIONS</h4>
            If you contact us via the contact form or e-mail, the data you provide will be used to process
            your enquiry. The provision of the data is necessary for the processing and answering of your
            enquiry - without their provision we cannot answer your enquiry or at best only to a limited
            extent.<br />
            The legal basis for this processing is Art. 6 para. 1 lit. b) DSGVO.<br />
            Your data will be deleted as soon as your enquiry has been finally answered and there are no
            legal obligations to retain data, e.g. in the case of subsequent contract processing.<br/><br/>
            
            <h4 className="display-6 mb-4" style={{textAlign: 'center', fontSize: '1.7em'}}>GOOGLE FONTS</h4>
            We use Google Fonts on our website to display external fonts. This is a service provided by
            Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland, hereinafter referred
            to as "Google".<br/>
            Through certification under the EU-US Privacy Shield
            https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active, Google
            guarantees that EU data protection requirements are also complied with when processing
            data in the USA.<br/>
            To enable the display of certain fonts on our website, a connection to the Google server in
            the USA is established when our website is accessed.<br/>
            The legal basis is Art. 6 para. 1 lit. f) DSGVO. Our legitimate interest lies in the optimization
            and economic operation of our website. The connection to Google established when you call
            up our website enables Google to determine which website sent your request and to which IP
            address the display of the font is to be transmitted. Google offers further information at
            https://adssettings.google.com/authenticated https://policies.google.com/privacy, in
            particular on the possibilities of preventing the use of data.
        </div>
        <Footer />
      </>
    );
  }

export default DatenschutzUSA