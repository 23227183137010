
const Eingangstextfeld = props => {

    const {eingangstext} = props
  
    return (
        <>
            <br/>
            <div className="card">
                <div className="card-body" style={{textAlign: 'center', fontSize: '1.0em',backgroundColor: 'rgb(236, 236, 236)'}}>{eingangstext}</div>
            </div><br />
        </>
    );
  }
  
  export default Eingangstextfeld