
export const DBTables = {
    Keiner: 'keiner',
    ArbeitAngebote: 'arbeit_angebote',
    ArbeitGesuche: 'arbeit_gesuche',
    Partner: 'partner',
    Gastronomie: 'gastronomie',
    Kinder: 'kinder',
    Kultur: 'kultur',
    Helfen: 'helfen',
    HeilenSchulmedizin: 'heilen_schulmedizin',
    HeilenNatur: 'heilen_natur',
    HeilenSpirit: 'heilen_spirit',
    Shopping: 'shopping',
    WohnenVermieter: 'wohnen_vermieter',
    WohnenMieter: 'wohnen_mieter',
    Freizeit: 'freizeit',
    Freunde: 'freunde',
    StartUps: 'startups',
    Mitfahrgelegenheit: 'mitfahrgelegenheit',
    KritischeAnwaelte: 'kritische_anwaelte',
    Handwerk: 'handwerk',
    AutarkesLeben: 'autarkes_leben'
};