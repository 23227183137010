import { BundeslaenderLuxemburg } from "../../enums/bundeslaender";

const MenuLuxemburg = props => {

    const {bundesland, setBundesland} = props

    return (
         <div className="mb-3 mt-3">
                <select className="form-select form-control-lg" defaultValue={bundesland}  id="bundesland" name="bundesland" onChange={e => setBundesland(e.target.value)}>
                <option value={BundeslaenderLuxemburg.Capellen}>Capellen</option>
                <option value={BundeslaenderLuxemburg.EschAnDerAlzette}>Esch an der Alzette</option>
                <option value={BundeslaenderLuxemburg.Luxemburg}>Luxemburg</option>
                <option value={BundeslaenderLuxemburg.Mersch}>Mersch</option>
                <option value={BundeslaenderLuxemburg.Clerf}>Clerf</option>
                <option value={BundeslaenderLuxemburg.Diekirch}>Diekirch</option>
                <option value={BundeslaenderLuxemburg.Redingen}>Redingen</option>
                <option value={BundeslaenderLuxemburg.Vianden}>Vianden</option>
                <option value={BundeslaenderLuxemburg.Wiltz}>Wiltz</option>
                <option value={BundeslaenderLuxemburg.Echternach}>Echternach</option>
                <option value={BundeslaenderLuxemburg.Grevenmacher}>Grevenmacher</option>
                <option value={BundeslaenderLuxemburg.Remich}>Remich</option>
                </select>
         </div>
    );
}

export default MenuLuxemburg
