import { useTranslation } from "react-i18next";
import Footer from "../footer/Footer";
import Header from "../header/Header";

const UnserTeam = () => {

    const {t} = useTranslation('common')

    return (
        <>
        <div className='container'>
            <Header />
            <h4 className="display-6 mb-4" style={{textAlign: 'center', fontSize: '1.7em'}}>{t('team.Ueberschrift')}</h4>
            <p>{t('team.Beschreibung1')}</p>
            <p>{t('team.Beschreibung2')}</p>
            <p>{t('team.Beschreibung3')}<br/>
            <a href="https://t.me/NetzwerkkritischerExperten/18293" target="_blank" rel="noreferrer">https://t.me/NetzwerkkritischerExperten/18293</a>
            </p> 
            <p>{t('team.Beschreibung4')}<br/>
            <a href="https://t.me/NetzwerkkritischerExperten/18300" target="_blank" rel="noreferrer">https://t.me/NetzwerkkritischerExperten/18300</a></p> 
            <p>{t('team.Beschreibung8')}<br/>
            <a href="https://www.odysee.com/@Corona-Ausschuss:3/Sitzung-78---DE---Neue-Wege-aus-dem-Trauma:e" target="_blank" rel="noreferrer">www.odysee.com/@Corona-Ausschuss:3/Sitzung-78---DE---Neue-Wege-aus-dem-Trauma:e</a></p>
            <p>{t('team.Beschreibung5')}</p>
            <p>{t('team.Beschreibung51')}</p>
            <p><a href="https://conscious-love.com" target="_blank" rel="noreferrer">https://conscious-love.com</a></p> 
            <p>{t('team.Beschreibung6')}</p>
            <p>{t('team.Beschreibung7')}</p>
        </div>
        <Footer />
      </>
    );
  }

export default UnserTeam