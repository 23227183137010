import { useEffect } from "react";
import { BundeslaenderUSA } from "../../enums/bundeslaender";
import { Laender } from "../../enums/laender";

const LaenderwahlUSA = props => {

    const {land, setLand, setBundesland} = props

    const changeHandler = e => {
        // does nothing
    }

    useEffect(() => {
        setLand(Laender.USA)
        setBundesland(BundeslaenderUSA.Alaska)
        // eslint-disable-next-line
    }, [])

    return (
        <div className="mb-3 mt-3">
        <select className="form-select form-control-lg" defaultValue={land}  id="land" name="land" onChange={changeHandler}>
            <option value={Laender.USA}>USA</option>
        </select>
 </div>
    );
}

export default LaenderwahlUSA