import { BundeslaenderSchweiz } from "../../enums/bundeslaender";

const MenuSchweiz = props => {

    const {bundesland, setBundesland} = props

    return (
         <div className="mb-3 mt-3">
                <select className="form-select form-control-lg" defaultValue={bundesland}  id="bundesland" name="bundesland" onChange={e => setBundesland(e.target.value)}>
                    <option value={BundeslaenderSchweiz.Zuerich}>Zürich</option>
                    <option value={BundeslaenderSchweiz.Bern}>Bern</option>
                    <option value={BundeslaenderSchweiz.Luzern}>Luzern</option>
                    <option value={BundeslaenderSchweiz.Uri}>Uri</option>
                    <option value={BundeslaenderSchweiz.Schwyz}>Schwyz</option>
                    <option value={BundeslaenderSchweiz.Obwalden}>Obwalden</option>
                    <option value={BundeslaenderSchweiz.Nidwalden}>Nidwalden</option>
                    <option value={BundeslaenderSchweiz.Glarus}>Glarus</option>
                    <option value={BundeslaenderSchweiz.Zug}>Zug</option>
                    <option value={BundeslaenderSchweiz.Freiburg}>Freiburg</option>
                    <option value={BundeslaenderSchweiz.Solothurn}>Solothurn</option>
                    <option value={BundeslaenderSchweiz.BaselStadt}>Basel-Stadt</option>
                    <option value={BundeslaenderSchweiz.BaselLandschaft}>Basel-Landschaft</option>
                    <option value={BundeslaenderSchweiz.Schaffhausen}>Schaffhausen</option>
                    <option value={BundeslaenderSchweiz.AppenzellARh}>Appenzell A.Rh.</option>
                    <option value={BundeslaenderSchweiz.AppenzellIRh}>Appenzell I.Rh.</option>
                    <option value={BundeslaenderSchweiz.SanktGallen}>Sankt Gallen</option>
                    <option value={BundeslaenderSchweiz.Graubuenden}>Graubünden</option>
                    <option value={BundeslaenderSchweiz.Aargau}>Aargau</option>
                    <option value={BundeslaenderSchweiz.Thurgau}>Thurgau</option>
                    <option value={BundeslaenderSchweiz.Tessin}>Tessin</option>
                    <option value={BundeslaenderSchweiz.Waadt}>Waadt</option>
                    <option value={BundeslaenderSchweiz.Wallis}>Wallis</option>
                    <option value={BundeslaenderSchweiz.Neuenburg}>Neuenburg</option>
                    <option value={BundeslaenderSchweiz.Genf}>Genf</option>
                    <option value={BundeslaenderSchweiz.Jura}>Jura</option>
                </select>
         </div>
    );
}

export default MenuSchweiz