import { BundeslaenderOstbelgien } from "../../enums/bundeslaender";

const MenuOstbelgien = props => {

    const {bundesland, setBundesland} = props

    return (
         <div className="mb-3 mt-3">
                <select className="form-select form-control-lg" defaultValue={bundesland}  id="bundesland" name="bundesland" onChange={e => setBundesland(e.target.value)}>
                    <option value={BundeslaenderOstbelgien.Ostbelgien}>Deutschsprachige Gemeinschaft in Ostbelgien</option>
                </select>
         </div>
    );
}

export default MenuOstbelgien
