import { useState} from "react"
import axios from 'axios'
import { Success } from "../../../enums/success"
import { getApiString, inputRegex, validateCaptcha, validateInput } from "../../../validationLogic"
import SuccessAlert from "../../alerts/SuccessAlert"
import ErrorAlert from "../../alerts/ErrorAlert"
import { v4 as uuid_v4 } from "uuid";
import { Laender } from "../../../enums/laender"
import { BundeslaenderDeutschland } from "../../../enums/bundeslaender"
import LaenderwahlForm from "../../general/LaenderwahlForm"
import BundesLaenderwahlForm from "../../general/BundeslaenderwahlForm"
import Captcha from "../../general/Captcha"
import LoadingSpinner from "../../sonstige/LoadingSpinner"
import { DBTables } from "../../../enums/dbTables"
import KategorieWahlForm from "../../general/KategorieWahlForm"
import ButtonBig from "../../general/ButtonBig"
import { Link } from "react-router-dom"
import {useTranslation} from "react-i18next"
import LaenderwahlFormUSA from "../../usa/LaenderwahlFormUSA"
import { Languages } from "../../../enums/languages"
import Footer from "../../footer/Footer"
import Header from "../../header/Header"

const Neu = () => {

    const emptyForm = {
        id: '',
        vorname: '',
        name: '',
        email: '',
        ueberschrift: '',
        anzeigentext: '',
        webseite: '',
        adresse: '',
        plz: '',
        ort: '',
        land: Laender.Deutschland,
        bundesland: BundeslaenderDeutschland.BadenWuerttemberg
    }

    const {i18n, t} = useTranslation('common')

    const [form, setForm] = useState(emptyForm)
    const [dbTable, setDbTable] = useState(DBTables.Keiner)
    const [success, setSuccess] = useState(Success.NothingYet)
    const [loading, setLoading] = useState(false)
    const [captchaAsk, setCaptchaAsk] = useState({
        num1: Math.floor(Math.random() * 10), 
        num2: Math.floor(Math.random() * 10)
    })
    const [captchaResult, setCaptchaResult] = useState('')
    const [checkAgb, setCheckAgb] = useState(false)

    const clickHandler = () => {

        setSuccess(Success.NothingYet)

        if(dbTable === DBTables.Keiner){
            setSuccess(Success.WrongInputCategorie)
            return
        }

        if(!validateInput(form.vorname, inputRegex.pflichtfeld_max50)){
            setSuccess(Success.WrongInputVorname)
            return
        }
        if(!validateInput(form.name, inputRegex.pflichtfeld_max100)){
            setSuccess(Success.WrongInputName)
            return
        }
        if(!validateInput(form.email, inputRegex.email)){
            setSuccess(Success.WrongInputEmail)
            return
        }
        if(!validateInput(form.ueberschrift, inputRegex.pflichtfeld_max100)){
            setSuccess(Success.WrongInputUeberschrift)
            return
        }
        if(!validateInput(form.anzeigentext, inputRegex.pflichtfeld_max1000)){
            setSuccess(Success.WrongInputAnzeigentext)
            return
        }
        if(!validateInput(form.webseite, inputRegex.optionalesFeld)){
            setSuccess(Success.WrongInputWebseite)
            return
        }
        if(!validateInput(form.adresse, inputRegex.pflichtfeld_max200)){
            setSuccess(Success.WrongInputAdresse)
            return
        }
        if(!validateInput(form.plz, inputRegex.plz)){
            setSuccess(Success.WrongInputPlz)
            return
        }
        if(!validateInput(form.ort, inputRegex.pflichtfeld_max50)){
            setSuccess(Success.WrongInputOrt)
            return
        }
        if(!validateCaptcha(captchaResult, captchaAsk.num1, captchaAsk.num2)){
            setCaptchaAsk({
                        num1: Math.floor(Math.random() * 10), 
                        num2: Math.floor(Math.random() * 10)
                    })
            setSuccess(Success.WrongInputCaptcha)
            return
        }
        if(!checkAgb){
            setSuccess(Success.WrongInputAgb)
            return
        }

       

        setLoading(true)

        form.id = uuid_v4()
        axios.post(getApiString(i18n.language) + 'inserate/api/' + dbTable, form, {headers: {'Accept': 'application/json'}})
        // axios.post(process.env.REACT_APP_BASE_URL + 'inserate/api/' + dbTable, form, {headers: {'Accept': 'application/json'}})
        .then(() => {
            setSuccess(Success.Yes)
            setLoading(false)
        })
        .catch(() => {
            setSuccess(Success.No)
            setLoading(false)
        })
    }

    if(success === Success.Yes) return <>
                                            <div className='container'>
                                            <Header />
                                            <br /><br />
                                            <SuccessAlert message={t('alerts.Success')} /></div>
                                            <Footer />
                                        </>

    if(success === Success.No) return <>
                                            <div className='container'>
                                            <Header />
                                            <br /><br />
                                            <ErrorAlert message={t('alerts.ServerError')} /></div>
                                            <Footer />
                                        </>
  
    return (<>
        <div className='container'>
            <Header />
            <ul>
                <li>{t('neu.Subheading1')}</li>
                <li>{t('neu.Subheading2')}</li>
            </ul>
            <form>
                <KategorieWahlForm setDbTable={setDbTable}/>
                {i18n.language === Languages.DE && <LaenderwahlForm form={form} setForm={setForm}/>}
                {i18n.language === Languages.EN &&<LaenderwahlFormUSA form={form} setForm={setForm}/>}
                <BundesLaenderwahlForm form={form} setForm={setForm}/>
                <div className="mb-3 mt-3">
                    <label htmlFor="vorname" className="form-label">{t('neu.Vorname')}* ({t('neu.NichtVeroeffentlicht')})</label>
                    <input type="text" className="form-control" id="vorname" name="vorname" onChange={e => setForm({...form, vorname: e.target.value})}/>
                </div>
                <div className="mb-3 mt-3">
                    <label htmlFor="name" className="form-label">{t('neu.Name')}* ({t('neu.NichtVeroeffentlicht')})</label>
                    <input type="text" className="form-control" id="name" name="name" onChange={e => setForm({...form, name: e.target.value})}/>
                </div>
                <div className="mb-3 mt-3">
                    <label htmlFor="email" className="form-label">{t('neu.Email')}* ({t('neu.NichtVeroeffentlicht')})</label>
                    <input type="email" className="form-control" id="email" name="email" onChange={e => setForm({...form, email: e.target.value})}/>
                </div>
                <div className="mb-3 mt-3">
                    <label htmlFor="ueberschrift" className="form-label">{t('neu.Ueberschrift')}*</label>
                    <input type="text" className="form-control" id="ueberschrift" name="ueberschrift" onChange={e => setForm({...form, ueberschrift: e.target.value})}/>
                </div>
                <div className="mb-3 mt-3">
                    <label htmlFor="anzeigentext" className="form-label">{t('neu.Anzeigentext')}* ({t('neu.MaxZeichen')}: <span style={{color: form.anzeigentext.length <= 1000 ? 'black': 'red'}}>{form.anzeigentext.length} {t('neu.Zeichen')}</span>)</label>
                    <textarea className="form-control" rows="6" id="anzeigentext" name="anzeigentext" onChange={e => setForm({...form, anzeigentext: e.target.value})}></textarea>
                </div>
                <div className="mb-3 mt-3">
                    <label htmlFor="webseite" className="form-label">{t('home.Webseite')}</label>
                    <input type="text" className="form-control" id="webseite" name="webseite" onChange={e => setForm({...form, webseite: e.target.value})}/>
                </div>
                <div className="mb-3 mt-3">
                    <label htmlFor="name" className="form-label">{t('neu.Adresse')}* ({t('neu.NichtVeroeffentlicht')})</label>
                    <input type="text" className="form-control" id="adresse" name="adresse" onChange={e => setForm({...form, adresse: e.target.value})}/>
                </div>
                <div className="mb-3 mt-3">
                    <label htmlFor="plz" className="form-label">{t('home.PLZ')}*</label>
                    <input type="text" className="form-control" id="plz" name="plz" onChange={e => setForm({...form, plz: e.target.value})}/>
                </div>
                <div className="mb-3 mt-3">
                    <label htmlFor="ort" className="form-label">{t('home.Ort')}*</label>
                    <input type="text" className="form-control" id="ort" name="ort" onChange={e => setForm({...form, ort: e.target.value})}/>
                </div>
                <Captcha captchaAsk={captchaAsk} setCaptchaResult={setCaptchaResult}/>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="check1" name="option1" value={checkAgb} onChange={() => setCheckAgb(!checkAgb)}/>
                    <label className="form-check-label">{t('neu.Accept')} <Link to='/agb' target="_blank" rel="noopener noreferrer" style={{color: 'black'}}>{t('footer.AGB')}</Link></label>
                </div>
                <br />
                <ButtonBig clickHandler={clickHandler} buttonText={t('neu.Abschicken')}/>
                <p>*{t('neu.Pflichtfelder')}</p>
                {loading && <><br /><LoadingSpinner /></>}
            </form>
            
            <br />
            {success === Success.WrongInputVorname && <ErrorAlert message={t('alerts.InputErrorVorname')}/>}
            {success === Success.WrongInputName && <ErrorAlert message={t('alerts.InputErrorName')}/>}
            {success === Success.WrongInputEmail && <ErrorAlert message={t('alerts.InputErrorEmail')}/>}
            {success === Success.WrongInputUeberschrift && <ErrorAlert message={t('alerts.InputErrorUeberschrift')}/>}
            {success === Success.WrongInputAnzeigentext && <ErrorAlert message={t('alerts.InputErrorAnzeigentext')}/>}
            {success === Success.WrongInputWebseite && <ErrorAlert message={t('alerts.InputErrorWebseite')}/>}
            {success === Success.WrongInputAdresse && <ErrorAlert message={t('alerts.InputErrorAdresse')}/>}
            {success === Success.WrongInputPlz && <ErrorAlert message={t('alerts.InputErrorPlz')}/>}
            {success === Success.WrongInputOrt && <ErrorAlert message={t('alerts.InputErrorOrt')}/>}
            {success === Success.WrongInputCaptcha && <ErrorAlert message={t('alerts.InputErrorCaptcha')}/>}
            {success === Success.WrongInputAgb && <ErrorAlert message={t('alerts.InputErrorAgb')}/>}
            {success === Success.WrongInputCategorie && <ErrorAlert message={t('alerts.InputErrorCategorie')}/>}
            </div>
        <Footer />
      </>
    );
  }
  
  export default Neu
  


