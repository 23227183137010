import { useState } from "react"
import { useTranslation } from "react-i18next"
import { PageElement } from "../../../enums/pageElement"

const Angebot = props => {

  const {angebot, setShowPageElement, setDetailAngebot} = props

  const [hover, setHover] = useState(false)
  const {t} = useTranslation('common')

  const showDetail = () => {
    setShowPageElement(PageElement.Detail)
    setDetailAngebot(angebot)
  }

  const getstyle = () => {
    if(hover) return {cursor: 'pointer', color: 'grey', marginBottom: '3px'}
    return {cursor: 'pointer', marginBottom: '3px'}
  }

  return (
    <><li 
        className="list-group-item" 
        style={getstyle()} 
        onMouseEnter={() => setHover(true)} 
        onMouseLeave={() => setHover(false)} 
        onClick={showDetail}>{angebot.ueberschrift} <small style={{fontSize: '0.8em', color: 'grey'}}>{t('home.KlickDetails')}</small><span style={{float: 'right'}}>{t('home.PLZ')}: {angebot.plz}</span>
    </li></>
  );
}

export default Angebot
