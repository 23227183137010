import { ArbeitCategories } from '../../enums/arbeitCategories';
import { DBTables } from '../../enums/dbTables';
import Inserate from './Inserate';
import Grundauswahl from './Grundauswahl/Grundauswahl';

const GrundauswahlArbeitLogic = props => {

    const {plz, setPlz, arbeitCategory, setArbeitCategory, land, setLand, bundesland, setBundesland, eingangstext} = props

    return (<>
                {arbeitCategory === ArbeitCategories.Startseite && <><br /><div className="card">
                    <div className="card-body" style={{textAlign: 'center', fontSize: '1.0em',backgroundColor: 'rgb(236, 236, 236)'}}>{eingangstext}</div>
                </div><br /></>}
                {arbeitCategory === ArbeitCategories.Startseite && <Grundauswahl setArbeitCategory={setArbeitCategory}/>}
                {arbeitCategory === ArbeitCategories.Angebote && <Inserate plz={plz} setPlz={setPlz} land={land} setLand={setLand} bundesland={bundesland} setBundesland={setBundesland} dbTable={DBTables.ArbeitAngebote} eingangstext={eingangstext}/>}
                {arbeitCategory === ArbeitCategories.Gesuche && <Inserate plz={plz} setPlz={setPlz} land={land} setLand={setLand} bundesland={bundesland} setBundesland={setBundesland} dbTable={DBTables.ArbeitGesuche} eingangstext={eingangstext}/>}
            </>)
}

export default GrundauswahlArbeitLogic