import Feedback from "./components/feedback/Feedback"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/home/Home"
import Spenden from "./components/spenden/Spenden";
import Agb from "./components/agb/Agb";
import Impressum from "./components/impressum/Impressum";
import UnserTeam from "./components/unserteam/UnserTeam";
import Datenschutz from "./components/datenschutz/Datenschutz";
import Neu from "./components/arbeit/angebot/Neu"
import { Languages } from "./enums/languages";
import AgbUSA from "./components/agb/AgbUSA";
import { useTranslation } from "react-i18next";
import DatenschutzUSA from "./components/datenschutz/DatenschutzUSA";

const App = () => {

  const {i18n} = useTranslation('common')

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/neu' element={<Neu />} />
        <Route path='/spenden' element={<Spenden />} />
        {i18n.language === Languages.DE && <Route path='/agb' element={<Agb />} />}
        {i18n.language === Languages.EN && <Route path='/agb' element={<AgbUSA />} />}
        <Route path='/impressum' element={<Impressum />} />
        <Route path='/unserteam' element={<UnserTeam />} />
        {i18n.language === Languages.DE && <Route path='/datenschutz' element={<Datenschutz />} />}
        {i18n.language === Languages.EN && <Route path='/datenschutz' element={<DatenschutzUSA />} />}
        <Route path='/feedback' element={<Feedback />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App
