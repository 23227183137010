import { Link } from "react-router-dom";
import "../../index.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import {useTranslation} from "react-i18next"

const Header = () => {

  const {i18n, t} = useTranslation('common')

    return (
      <><div style={{
          backgroundImage: `url(${process.env.PUBLIC_URL + '/img/sunrise-over-hills.jpg'})`, 
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',  
          borderRadius: '10px'}}>
        <br/>
        <Link to="/" style={{textDecoration: 'none', color: 'black'}}>
          <h1 style={{textAlign: 'center', 
                      fontFamily: 'Corinthia, sans-serif', 
                      fontSize: '4em'
                    }}>
            Healing the World with a Human Touch
          </h1>
          <h4 style={{textAlign: 'center', 
                      fontFamily: 'Corinthia, sans-serif', 
                      fontSize: '2.5em'}}>{t('header.Subheading')}</h4>
        </Link>
                        <div className="dropdown" style={{textAlign: 'center'}}>
                          <button type="button" className="btn btn-lg dropdown-toggle" data-bs-toggle="dropdown">
                             Where in our <FontAwesomeIcon icon={faGlobe}/> are you?
                          </button>
                          <ul className="dropdown-menu">
                            <li><button className="dropdown-item" onClick={() => i18n.changeLanguage('de')}>Germanspeaking Countries</button></li>
                            <li><button className="dropdown-item" onClick={() => i18n.changeLanguage('en')}>USA (English)</button></li>
                          </ul>
                        </div>
      </div>
      <br />
      </>
    );
  }
  
  export default Header