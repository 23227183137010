import { useTranslation } from "react-i18next";

const PlzWahl = props => {

    const {plz, setPlz} = props
    const {t} = useTranslation('common')

    return (
        <>
            <input type="text" className="form-control form-control-lg" style={{border: '1px solid lightgreen'}} placeholder={plz ? plz : t('home.PLZFilter')} onChange={e => setPlz(e.target.value)}/>
        </>
  );
}

export default PlzWahl