import Header from "../header/Header";
import Footer from "../footer/Footer"
import { useState, useEffect } from "react";
import axios from "axios";
import FeedbackCard from "./FeedbackCard";
import LoadingSpinner from "../sonstige/LoadingSpinner";
import { useTranslation } from "react-i18next";
import {getApiString} from "../../validationLogic"

const Feedback = () => {

    const [feedbacks, setFeedbacks] = useState([])
    const {i18n, t} = useTranslation('common')

    useEffect(() => {
        axios.get(getApiString(i18n.language) + 'feedback/api')
        .then((response) => {
            setFeedbacks(response.data.data)
        })
        .catch((error) => {
          console.log(error)
        })
      }, [i18n.language])

    return (
      <>
        <div className="container">
        <Header />
            <h4 className="display-6 mb-4" style={{textAlign: 'center', fontSize: '1.7em'}}>{t('feedback.Ueberschrift')}</h4>
            <p>{t('feedback.Beschreibung1')}: <br /> <span style={{fontWeight: 'bold'}}>{t('feedback.Mailaddress')}</span></p>
            <hr style={{border: '3px solid grey'}}/>
            {feedbacks.length === 0 && <LoadingSpinner />}
            {feedbacks && feedbacks.map(el => <FeedbackCard key={el.id} feedback={el}/>)}
        </div>
        <Footer />
      </>
    );
  }
  
  export default Feedback