
import {useState} from 'react'

const ButtonBig = props => {

    const {clickHandler, buttonText} = props

    const [hover, setHover] = useState(false)

    const style = {
      border: '2px solid grey',
      backgroundColor: 'rgb(252, 252, 90)'
    }

    const styleHover = {
      border: '2px solid grey', 
      backgroundColor: 'rgb(252, 252, 140)'
    }
  
    return (
        
        <div className="d-grid">
            <button 
              type="button" 
              onMouseLeave={() => setHover(false)}
              onMouseEnter={() => setHover(true)}
              style={hover ? styleHover : style}
              className="btn btn-warning btn-block btn-lg" 
              onClick={clickHandler}>
              {buttonText}
            </button>
        </div>
        
    );
  }
  
  export default ButtonBig