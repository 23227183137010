
import {useState} from 'react'
import { useTranslation } from 'react-i18next'
import { ArbeitCategories } from '../../enums/arbeitCategories'
import { BundeslaenderDeutschland } from '../../enums/bundeslaender'
import { Categories } from '../../enums/categories'
import { DBTables } from '../../enums/dbTables'
import { HeilenCategories } from '../../enums/heilenCategories'
import { Laender } from '../../enums/laender'
import { WohnenCategories } from '../../enums/wohnenCategoties'
import GrundauswahlArbeitLogic from '../arbeit/GrundauswahlArbeitLogic'
import Inserate from '../arbeit/Inserate'
import Footer from '../footer/Footer'
import Header from '../header/Header'
import GrundauswahlHeilenLogic from '../heilen/GrundAuswahlHeilenLogic'
import Startseite from '../startseite/Startseite'
import GrundauswahlWohnenLogic from '../wohnen/GrundAuswahlWohnenLogic'

const Home = () => {

  const [category, setCategory] = useState(Categories.Startseite)
  const [arbeitCategory, setArbeitCategory] = useState(ArbeitCategories.Startseite)
  const [heilenCategory, setHeilenCategory] = useState(HeilenCategories.Startseite)
  const [wohnenCategory, setWohnenCategory] = useState(WohnenCategories.Startseite)

  const [land, setLand] = useState(Laender.Deutschland)
  const [bundesland, setBundesland] = useState(BundeslaenderDeutschland.BadenWuerttemberg)
  const [plz, setPlz] = useState(undefined)

  const {t} = useTranslation('common')

  const clickArbeit = () => {
    setCategory(Categories.Arbeit)
    setArbeitCategory(ArbeitCategories.Startseite)
  }

  const clickHeilen = () => {
    setCategory(Categories.Heilen)
    setHeilenCategory(HeilenCategories.Startseite)
  }

  const clickWohnen = () => {
    setCategory(Categories.Wohnen)
    setWohnenCategory(WohnenCategories.Startseite)
  }

  return (
      <>
        <div className='container'>
          <Header />
          <Startseite land={land} setLand={setLand} bundesland={bundesland} setBundesland={setBundesland}/>
          <br />
          <div style={{textAlign:'center', backgroundImage: `url(${process.env.PUBLIC_URL + '/img/malediven.jpg'})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', opacity: '1.0', borderRadius: '10px'}}>
          <button type="button" style={category === Categories.Arbeit ?  {border: '3.5px solid black', fontWeight: 'bold'} : {border: '0.5px solid black'}} className="btn p-3 m-1" onClick={clickArbeit}>{t('home.Arbeit')}</button>
          <button type="button" style={category === Categories.Partner ?  {border: '3.5px solid black', fontWeight: 'bold'} : {border: '0.5px solid black'}} className="btn p-3 m-1" onClick={() => setCategory(Categories.Partner)}>{t('home.Partner')}</button>
          <button type="button" style={category === Categories.Gastronomie ?  {border: '3.5px solid black', fontWeight: 'bold'} : {border: '0.5px solid black'}} className="btn p-3 m-1" onClick={() => setCategory(Categories.Gastronomie)}>{t('home.Gastronomie')}</button>
          <button type="button" style={category === Categories.Kinder ?  {border: '3.5px solid black', fontWeight: 'bold'} : {border: '0.5px solid black'}} className="btn p-3 m-1" onClick={() => setCategory(Categories.Kinder)}>{t('home.Kinder')}</button>
          <button type="button" style={category === Categories.Kultur ?  {border: '3.5px solid black', fontWeight: 'bold'} : {border: '0.5px solid black'}} className="btn p-3 m-1" onClick={() => setCategory(Categories.Kultur)}>{t('home.Kultur')}</button>
          <button type="button" style={category === Categories.Helfen ?  {border: '3.5px solid black', fontWeight: 'bold'} : {border: '0.5px solid black'}} className="btn p-3 m-1" onClick={() => setCategory(Categories.Helfen)}>{t('home.Helfen')}</button>
          <button type="button" style={category === Categories.Heilen ?  {border: '3.5px solid black', fontWeight: 'bold'} : {border: '0.5px solid black'}} className="btn p-3 m-1" onClick={clickHeilen}>{t('home.Heilen')}</button>
          <button type="button" style={category === Categories.Shopping ?  {border: '3.5px solid black', fontWeight: 'bold'} : {border: '0.5px solid black'}} className="btn p-3 m-1" onClick={() => setCategory(Categories.Shopping)}>{t('home.Shopping')}</button>
          <button type="button" style={category === Categories.Wohnen ?  {border: '3.5px solid black', fontWeight: 'bold'} : {border: '0.5px solid black'}} className="btn p-3 m-1" onClick={clickWohnen}>{t('home.Wohnen')}</button>
          <button type="button" style={category === Categories.Freizeit ?  {border: '3.5px solid black', fontWeight: 'bold'} : {border: '0.5px solid black'}} className="btn p-3 m-1" onClick={() => setCategory(Categories.Freizeit)}>{t('home.Freizeit')}</button>
          <button type="button" style={category === Categories.Freunde ?  {border: '3.5px solid black', fontWeight: 'bold'} : {border: '0.5px solid black'}} className="btn p-3 m-1" onClick={() => setCategory(Categories.Freunde)}>{t('home.Freunde')}</button>
          <button type="button" style={category === Categories.StartUps ?  {border: '3.5px solid black', fontWeight: 'bold'} : {border: '0.5px solid black'}} className="btn p-3 m-1" onClick={() => setCategory(Categories.StartUps)}>{t('home.StartUps')}</button>
          <button type="button" style={category === Categories.Mitfahrgelegenheit ?  {border: '3.5px solid black', fontWeight: 'bold'} : {border: '0.5px solid black'}} className="btn p-3 m-1" onClick={() => setCategory(Categories.Mitfahrgelegenheit)}>{t('home.Mitfahrgelegenheit')}</button>
          <button type="button" style={category === Categories.KritischeAnwaelte ?  {border: '3.5px solid black', fontWeight: 'bold'} : {border: '0.5px solid black'}} className="btn p-3 m-1" onClick={() => setCategory(Categories.KritischeAnwaelte)}>{t('home.KritischeAnwaelte')}</button>
          <button type="button" style={category === Categories.Handwerk ?  {border: '3.5px solid black', fontWeight: 'bold'} : {border: '0.5px solid black'}} className="btn p-3 m-1" onClick={() => setCategory(Categories.Handwerk)}>{t('home.Handwerk')}</button>
          <button type="button" style={category === Categories.AutarkesLeben ?  {border: '3.5px solid black', fontWeight: 'bold'} : {border: '0.5px solid black'}} className="btn p-3 m-1" onClick={() => setCategory(Categories.AutarkesLeben)}>{t('home.AutarkesLeben')}</button>
          </div>
          {category === Categories.Arbeit && <GrundauswahlArbeitLogic plz={plz} setPlz={setPlz} arbeitCategory={arbeitCategory} setArbeitCategory={setArbeitCategory} land={land} setLand={setLand} bundesland={bundesland} setBundesland={setBundesland} eingangstext={t('home.ArbeitEingangstext')}/>}
          {category === Categories.Partner && <Inserate plz={plz} setPlz={setPlz} land={land} setLand={setLand} bundesland={bundesland} setBundesland={setBundesland} dbTable={DBTables.Partner} eingangstext={t('home.PartnerEingangstext')}/>}
          {category === Categories.Gastronomie && <Inserate plz={plz} setPlz={setPlz} land={land} setLand={setLand} bundesland={bundesland} setBundesland={setBundesland} dbTable={DBTables.Gastronomie} eingangstext={t('home.GastronomieEingangstext')}/>}
          {category === Categories.Kinder && <Inserate plz={plz} setPlz={setPlz} land={land} setLand={setLand} bundesland={bundesland} setBundesland={setBundesland} dbTable={DBTables.Kinder} eingangstext={t('home.KinderEingangstext')}/>}
          {category === Categories.Kultur && <Inserate plz={plz} setPlz={setPlz} land={land} setLand={setLand} bundesland={bundesland} setBundesland={setBundesland} dbTable={DBTables.Kultur} eingangstext={t('home.KulturEingangstext')}/>}
          {category === Categories.Helfen && <Inserate plz={plz} setPlz={setPlz} land={land} setLand={setLand} bundesland={bundesland} setBundesland={setBundesland} dbTable={DBTables.Helfen} eingangstext={t('home.HelfenEingangstext')}/>}
          {category === Categories.Heilen && <GrundauswahlHeilenLogic plz={plz} setPlz={setPlz} heilenCategory={heilenCategory} setHeilenCategory={setHeilenCategory} land={land} setLand={setLand} bundesland={bundesland} setBundesland={setBundesland} eingangstext={t('home.HeilenEingangstext')}/>}
          {category === Categories.Shopping && <Inserate plz={plz} setPlz={setPlz} land={land} setLand={setLand} bundesland={bundesland} setBundesland={setBundesland} dbTable={DBTables.Shopping} eingangstext={t('home.ShoppingEingangstext')}/>}
          {category === Categories.Wohnen && <GrundauswahlWohnenLogic plz={plz} setPlz={setPlz} wohnenCategory={wohnenCategory} setWohnenCategory={setWohnenCategory} land={land} setLand={setLand} bundesland={bundesland} setBundesland={setBundesland} dbTable={DBTables.Partner} eingangstext={t('home.WohnenEingangstext')}/>}
          {category === Categories.Freizeit && <Inserate plz={plz} setPlz={setPlz} land={land} setLand={setLand} bundesland={bundesland} setBundesland={setBundesland} dbTable={DBTables.Freizeit} eingangstext={t('home.FreizeitEingangstext')}/>}
          {category === Categories.Freunde && <Inserate plz={plz} setPlz={setPlz} land={land} setLand={setLand} bundesland={bundesland} setBundesland={setBundesland} dbTable={DBTables.Freunde} eingangstext={t('home.FreundeEingangstext')}/>}
          {category === Categories.StartUps && <Inserate plz={plz} setPlz={setPlz} land={land} setLand={setLand} bundesland={bundesland} setBundesland={setBundesland} dbTable={DBTables.StartUps} eingangstext={t('home.StartUpsEingangstext')}/>}
          {category === Categories.Mitfahrgelegenheit && <Inserate plz={plz} setPlz={setPlz} land={land} setLand={setLand} bundesland={bundesland} setBundesland={setBundesland} dbTable={DBTables.Mitfahrgelegenheit} eingangstext={t('home.MitfahrgelegenheitEingangstext')}/>}
          {category === Categories.KritischeAnwaelte && <Inserate plz={plz} setPlz={setPlz} land={land} setLand={setLand} bundesland={bundesland} setBundesland={setBundesland} dbTable={DBTables.KritischeAnwaelte} eingangstext={t('home.KritischeAnwaelteEingangstext')}/>}
          {category === Categories.Handwerk && <Inserate plz={plz} setPlz={setPlz} land={land} setLand={setLand} bundesland={bundesland} setBundesland={setBundesland} dbTable={DBTables.Handwerk} eingangstext={t('home.HandwerkEingangstext')}/>}
          {category === Categories.AutarkesLeben && <Inserate plz={plz} setPlz={setPlz} land={land} setLand={setLand} bundesland={bundesland} setBundesland={setBundesland} dbTable={DBTables.AutarkesLeben} eingangstext={t('home.AutarkesLebenEingangstext')}/>}
        </div>
        <Footer />
      </>
  );
}

export default Home
