import {useState, useEffect} from 'react'
import axios from 'axios'
import Angebot from './angebot/Angebot'
import LoadingSpinner from '../sonstige/LoadingSpinner'
import AngebotDetail from './angebot/AngebotDetail'
import { PageElement } from '../../enums/pageElement'
import ErrorAlert from '../alerts/ErrorAlert'
import PlzWahl from '../startseite/PlzWahl'
import Eingangstextfeld from '../general/Eingangstextfeld'
import { useTranslation } from 'react-i18next'
import { getApiString } from '../../validationLogic'


const Inserate = props => {

    const {plz, setPlz, land, bundesland, dbTable, eingangstext} = props

    const [angebote, setAngebote] = useState(null)
    const [showPageElement, setShowPageElement] = useState(PageElement.Start)
    const [detailAngebot, setDetailAngebot] = useState({})
    const {i18n, t} = useTranslation('common')

    useEffect(() => {
      setAngebote(null)
      axios.get(getApiString(i18n.language) + 'inserate/api/'+dbTable+'/'+land+'/'+bundesland)
      .then((response) => {
        setAngebote(response.data.data)
      })
      .catch((error) => {
        console.log(error)
      })
    }, [land, bundesland, dbTable, i18n.language])

    const getAngebotComponent = el => <Angebot 
                                        key={el.id} 
                                        angebot={el} 
                                        setShowPageElement={setShowPageElement} 
                                        setDetailAngebot={setDetailAngebot}
                                      />

    const inserateFilter = el => {
      if(!plz){
        return getAngebotComponent(el)
      }
      if(el.plz.startsWith(plz)){
        return getAngebotComponent(el)
      } else {
        return null
      }
    }

    return (<>
              <br/>
              <Eingangstextfeld eingangstext={eingangstext}/>
              <h5 className="display-6" style={{textAlign: 'center', fontSize: '1.2em'}}>{t('home.Ergebnisse')}</h5><br />
              <PlzWahl plz={plz} setPlz={setPlz}/><br />
              <ul className="list-group list-group-flush">
                {showPageElement === PageElement.Start && angebote && angebote.map(inserateFilter)}
              </ul>
              {!angebote && <LoadingSpinner />}
              {angebote && angebote.map(inserateFilter).every(el => el === null) && showPageElement !== PageElement.Form && <ErrorAlert message={t('alerts.NothingFound')}/>}
              {showPageElement === PageElement.Detail && <AngebotDetail detailAngebot={detailAngebot} setShowPageElement={setShowPageElement} dbTable={dbTable}/>}
            </>
  );
}

export default Inserate
