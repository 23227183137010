import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LoadingSpinner from "../sonstige/LoadingSpinner"
import ButtonBig from "./ButtonBig";
import Captcha from "./Captcha";

const MailBackForm = props => {

    const {clickHandler, form, setForm, loading, captchaAsk, setCaptchaResult, checkAgb, setCheckAgb} = props
    const {t} = useTranslation('common')
  
    return (
        <>
            <br />
            <ul>
                <li>{t('home.Subheading1')}</li>
                <li>{t('home.Subheading2')}</li>
            </ul>
            <form>
                <div className="mb-3 mt-3">
                    <label htmlFor="vorname" className="form-label">{t('neu.Vorname')}*</label>
                    <input type="text" className="form-control" id="vorname" name="vorname" value={form.vorname} onChange={e => setForm({...form, vorname: e.target.value})}/>
                </div>
                <div className="mb-3 mt-3">
                    <label htmlFor="name" className="form-label">{t('neu.Name')}*</label>
                    <input type="text" className="form-control" id="name" name="name" value={form.name} onChange={e => setForm({...form, name: e.target.value})}/>
                </div>
                <div className="mb-3 mt-3">
                    <label htmlFor="email" className="form-label">{t('neu.Email')}*</label>
                    <input type="email" className="form-control" id="email" name="email" value={form.email} onChange={e => setForm({...form, email: e.target.value})}/>
                </div>
                <div className="mb-3 mt-3">
                    <label htmlFor="nachricht" className="form-label">{t('neu.Nachricht')}* ({t('neu.MaxZeichen')}: <span style={{color: form.nachricht.length <= 1000 ? 'black': 'red'}}>{form.nachricht.length} {t('neu.Zeichen')}</span>)</label>
                    <textarea className="form-control" rows="5" id="nachricht" name="nachricht" value={form.nachricht} onChange={e => setForm({...form, nachricht: e.target.value})}></textarea>
                </div>
                <Captcha captchaAsk={captchaAsk} setCaptchaResult={setCaptchaResult}/>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="check1" name="option1" value={checkAgb} onChange={() => setCheckAgb(!checkAgb)}/>
                    <label className="form-check-label">{t('neu.Accept')} <Link to='/agb' target="_blank" rel="noopener noreferrer" style={{color: 'black'}}>{t('footer.AGB')}</Link></label>
                </div>
                <br />
                <ButtonBig clickHandler={clickHandler} buttonText={t('neu.Abschicken')}/>
                <p>*{t('neu.Pflichtfelder')}</p>
                {loading && <><br /><LoadingSpinner /></>}
            </form>  
            <br />
        </>
    );
  }
  
  export default MailBackForm