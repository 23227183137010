import { BundeslaenderUSA } from "../../enums/bundeslaender";

const MenuUSA = props => {

    const {bundesland, setBundesland} = props

    return (
         <div className="mb-3 mt-3">
                <select className="form-select form-control-lg" defaultValue={bundesland}  id="bundesland" name="bundesland" onChange={e => setBundesland(e.target.value)}>
                    <option value={BundeslaenderUSA.Alaska}>Alaska</option>
                    <option value={BundeslaenderUSA.Texas}>Texas</option>
                    <option value={BundeslaenderUSA.California}>California</option>
                    <option value={BundeslaenderUSA.Montana}>Montana</option>
                    <option value={BundeslaenderUSA.NewMexico}>New Mexico</option>
                    <option value={BundeslaenderUSA.Arizona}>Arizona</option>
                    <option value={BundeslaenderUSA.Nevada}>Nevada</option>
                    <option value={BundeslaenderUSA.Colorado}>Colorado</option>
                    <option value={BundeslaenderUSA.Oregon}>Oregon</option>
                    <option value={BundeslaenderUSA.Wyoming}>Wyoming</option>
                    <option value={BundeslaenderUSA.Michigan}>Michigan</option>
                    <option value={BundeslaenderUSA.Minnesota}>Minnesota</option>
                    <option value={BundeslaenderUSA.Utah}>Utah</option>
                    <option value={BundeslaenderUSA.Idaho}>Idaho</option>
                    <option value={BundeslaenderUSA.Kansas}>Kansas</option>
                    <option value={BundeslaenderUSA.Nebraska}>Nebraska</option>
                    <option value={BundeslaenderUSA.SouthDakota}>South Dakota</option>
                    <option value={BundeslaenderUSA.Washington}>Washington</option>
                    <option value={BundeslaenderUSA.NorthDakota}>North Dakota</option>
                    <option value={BundeslaenderUSA.Oklahoma}>Oklahoma</option>
                    <option value={BundeslaenderUSA.Missouri}>Missouri</option>
                    <option value={BundeslaenderUSA.Florida}>Florida</option>
                    <option value={BundeslaenderUSA.Wisconsin}>Wisconsin</option>
                    <option value={BundeslaenderUSA.Georgia}>Georgia</option>
                    <option value={BundeslaenderUSA.Illinois}>Illinois</option>
                    <option value={BundeslaenderUSA.Iowa}>Iowa</option>
                    <option value={BundeslaenderUSA.NewYork}>New York</option>
                    <option value={BundeslaenderUSA.NorthCarolina}>North Carolina</option>
                    <option value={BundeslaenderUSA.Arkansas}>Arkansas</option>
                    <option value={BundeslaenderUSA.Alabama}>Alabama</option>
                    <option value={BundeslaenderUSA.Louisiana}>Louisiana</option>
                    <option value={BundeslaenderUSA.Mississippi}>Mississippi</option>
                    <option value={BundeslaenderUSA.Pennsylvania}>Pennsylvania</option>
                    <option value={BundeslaenderUSA.Ohio}>Ohio</option>
                    <option value={BundeslaenderUSA.Virginia}>Virginia</option>
                    <option value={BundeslaenderUSA.Tennessee}>Tennessee</option>
                    <option value={BundeslaenderUSA.Kentucky}>Kentucky</option>
                    <option value={BundeslaenderUSA.Indiana}>Indiana</option>
                    <option value={BundeslaenderUSA.Maine}>Maine</option>
                    <option value={BundeslaenderUSA.SouthCarolina}>South Carolina</option>
                    <option value={BundeslaenderUSA.WestVirginia}>West Virginia</option>
                    <option value={BundeslaenderUSA.Maryland}>Maryland</option>
                    <option value={BundeslaenderUSA.Hawaii}>Hawaii</option>
                    <option value={BundeslaenderUSA.Massachusetts}>Massachusetts</option>
                    <option value={BundeslaenderUSA.Vermont}>Vermont</option>
                    <option value={BundeslaenderUSA.NewHampshire}>New Hampshire</option>
                    <option value={BundeslaenderUSA.NewJersey}>New Jersey</option>
                    <option value={BundeslaenderUSA.Connecticut}>Connecticut</option>
                    <option value={BundeslaenderUSA.Delaware}>Delaware</option>
                    <option value={BundeslaenderUSA.RhodeIsland}>Rhode Island</option>
                    <option value={BundeslaenderUSA.DistrictOfColumbia}>District of Columbia</option>
                </select>
         </div>
    );
}

export default MenuUSA
