import { BundeslaenderDeutschland } from "../../enums/bundeslaender";

const MenuDeutschland = props => {

    const {bundesland, setBundesland} = props

    return (
         <div className="mb-3 mt-3">
                <select className="form-select form-control-lg" defaultValue={bundesland}  id="bundesland" name="bundesland" onChange={e => setBundesland(e.target.value)}>
                    <option value={BundeslaenderDeutschland.BadenWuerttemberg}>Baden-Württemberg</option>
                    <option value={BundeslaenderDeutschland.Bayern}>Bayern</option>
                    <option value={BundeslaenderDeutschland.Berlin}>Berlin</option>
                    <option value={BundeslaenderDeutschland.Brandenburg}>Brandenburg</option>
                    <option value={BundeslaenderDeutschland.Bremen}>Bremen</option>
                    <option value={BundeslaenderDeutschland.Hamburg}>Hamburg</option>
                    <option value={BundeslaenderDeutschland.Hessen}>Hessen</option>
                    <option value={BundeslaenderDeutschland.MecklenburgVorpommern}>Mecklenburg-Vorpommern</option>
                    <option value={BundeslaenderDeutschland.Niedersachsen}>Niedersachsen</option>
                    <option value={BundeslaenderDeutschland.NordrheinWestfalen}>Nordrhein-Westfalen</option>
                    <option value={BundeslaenderDeutschland.RheinlandPfalz}>Rheinland-Pfalz</option>
                    <option value={BundeslaenderDeutschland.Saarland}>Saarland</option>
                    <option value={BundeslaenderDeutschland.Sachsen}>Sachsen</option>
                    <option value={BundeslaenderDeutschland.SachsenAnhalt}>Sachsen-Anhalt</option>
                    <option value={BundeslaenderDeutschland.SchleswigHolstein}>Schleswig-Holstein</option>
                    <option value={BundeslaenderDeutschland.Thueringen}>Thüringen</option>
                </select>
         </div>
    );
}

export default MenuDeutschland
