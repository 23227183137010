import { BundeslaenderLuxemburg } from "../../enums/bundeslaender";

const BundeslaenderwahlFormLuxemburg = props => {

    const {form, setForm} = props

    return (
        <div className="mb-3 mt-3">
            <label htmlFor="bundesland" className="form-label">In welchem Bundesland / Kanton soll Ihre Anzeige geschaltet werden?*</label>
            <select className="form-select form-control-lg" defaultValue={BundeslaenderLuxemburg.Capellen}  id="bundesland" name="bundesland" onChange={e => setForm({...form, bundesland: e.target.value})}>
                <option value={BundeslaenderLuxemburg.Capellen}>Capellen</option>
                <option value={BundeslaenderLuxemburg.EschAnDerAlzette}>Esch an der Alzette</option>
                <option value={BundeslaenderLuxemburg.Luxemburg}>Luxemburg</option>
                <option value={BundeslaenderLuxemburg.Mersch}>Mersch</option>
                <option value={BundeslaenderLuxemburg.Clerf}>Clerf</option>
                <option value={BundeslaenderLuxemburg.Diekirch}>Diekirch</option>
                <option value={BundeslaenderLuxemburg.Redingen}>Redingen</option>
                <option value={BundeslaenderLuxemburg.Vianden}>Vianden</option>
                <option value={BundeslaenderLuxemburg.Wiltz}>Wiltz</option>
                <option value={BundeslaenderLuxemburg.Echternach}>Echternach</option>
                <option value={BundeslaenderLuxemburg.Grevenmacher}>Grevenmacher</option>
                <option value={BundeslaenderLuxemburg.Remich}>Remich</option>
            </select>
        </div>
    );
}

export default BundeslaenderwahlFormLuxemburg