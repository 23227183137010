
import ButtonBig from "../general/ButtonBig";
import Bundeslaenderwahl from "./Bundeslaenderwahl";
import Laenderwahl from "./Laenderwahl";
import {useTranslation} from "react-i18next";
import LaenderwahlUSA from "../usa/LaenderwahlUSA";
import { Languages } from "../../enums/languages";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from 'axios';
import { getApiString } from "../../validationLogic";

const Startseite = props => {

    const {land, setLand, bundesland, setBundesland} = props
    const {t, i18n} = useTranslation('common')
    const [anzahlInserate, setAnzahlInserate] = useState(0)

    useEffect(() => {
        axios.get(getApiString(i18n.language) + 'inserate/getanzahlinserate')
        .then((response) => {
            setAnzahlInserate(response.data.data[0].anzahl)
        })
        .catch((error) => {
        //   console.log(error)
        })
    }, [i18n.language])

    return (
        <div>
             {anzahlInserate > 1000 && <p style={{textAlign: 'center', color: 'green'}}>- {t('home.Aktuell')} <strong>{anzahlInserate}</strong> {t('home.AnzahlInserate')} -</p>}
        <Link to="/neu" style={{ textDecoration: 'none' }}><ButtonBig clickHandler={() => {/* does nothing, link does it */}} buttonText={t('home.ButtonNeuesInserat')}/><hr style={{border: '3px solid grey'}}/></Link>
        <h5 className="display-6" style={{textAlign: 'center', fontSize: '1.7em'}}>{t('home.InserateFinden')}</h5>
        <h5 className="display-6" style={{textAlign: 'center', fontSize: '1.2em'}}>{t('home.Suchkriterien')}</h5>
        <div className="row">
            <div className="col">
                {i18n.language === Languages.DE && <Laenderwahl land={land} setLand={setLand} setBundesland={setBundesland}/>}
                {i18n.language === Languages.EN &&<LaenderwahlUSA land={land} setLand={setLand} setBundesland={setBundesland}/>}
            </div>
            <div className="col">
                <Bundeslaenderwahl land={land} bundesland={bundesland} setBundesland={setBundesland}/>
            </div>
        </div>
        <h5 className="display-6" style={{textAlign: 'center', fontSize: '1.2em'}}>{t('home.KategorieAuswaehlen')}</h5>
        </div>
  );
}

export default Startseite
