import { Laender } from "../../enums/laender";
import BundeslaenderwahlFormUSA from "../usa/BundeslaenderwahlFormUSA";
import BundeslaenderwahlFormDeutschland from "./BundeslaenderwahlFormDeutschland";
import BundeslaenderwahlFormLuxemburg from "./BundeslaenderwahlFormLuxemburg";
import BundeslaenderwahlFormOesterreich from "./BundeslaenderwahlFormOesterreich";
import BundeslaenderwahlFormOstbelgien from "./BundeslaenderwahlFormOstbelgien";
import BundeslaenderwahlFormSchweiz from "./BundeslaenderwahlFormSchweiz";

const BundesLaenderwahlForm = props => {

    const {form, setForm} = props

    return (<>
        {form.land === Laender.Deutschland && <BundeslaenderwahlFormDeutschland form={form} setForm={setForm}/>}
        {form.land === Laender.Oesterreich && <BundeslaenderwahlFormOesterreich form={form} setForm={setForm}/>}
        {form.land === Laender.Schweiz && <BundeslaenderwahlFormSchweiz form={form} setForm={setForm}/>}
        {form.land === Laender.Luxemburg && <BundeslaenderwahlFormLuxemburg form={form} setForm={setForm}/>}
        {form.land === Laender.Ostbelgien && <BundeslaenderwahlFormOstbelgien form={form} setForm={setForm}/>}
        {form.land === Laender.USA && <BundeslaenderwahlFormUSA form={form} setForm={setForm}/>}
        </>
    );
}

export default BundesLaenderwahlForm
